import React, { useEffect, useState } from 'react';
import CustomAlert from '../components/CustomAlert';
import { useUsersQuery } from '../hooks/queries/useUsersQuery';
import { User } from '../services/apiSLTP/users';
import { useJobsQuery } from '../hooks/queries/useJobsQuery';
import { Job } from '../services/apiSLTP/jobs';
import { Profile } from '../services/apiSLTP/profiles';
import { useProfilesQuery } from '../hooks/queries/useProfilesQuery';
import { useAuth } from '../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import TrucksSettingsRow from '../components/settingsRows/TrucksSettingsRow';
import { useTrucksTypesQuery } from '../hooks/queries/useTrucksTypesQuery';
import { useTrucksCompaniesQuery } from '../hooks/queries/useTrucksCompaniesQuery';
import { TruckType } from '../services/apiSLTP/materials/trucksTypes';
import { TruckCompany } from '../services/apiSLTP/materials/trucksCompanies';
import TrucksTypesSettingsRow from '../components/settingsRows/TrucksTypesSettingsRow';
import TrucksCompaniesSettingsRow from '../components/settingsRows/TrucksCompaniesSettingsRow';
import { useLoadNaturesQuery } from '../hooks/queries/useLoadNaturesQuery';
import LoadNaturesSettingsRow from '../components/settingsRows/LoadNaturesSettingsRow';
import { LoadNature } from '../services/apiSLTP/materials/loadNatures';
import Header from '../components/Header';
import { getItemFromLocalStorage, storeItemToLocalStorage } from '../utils/localStorage';
import { localStorageKeys } from '../utils/enums';
import Icon from '../components/Icon';
import { capitalizeFirstLetter } from '../utils/capitalize';
import CustomTabs from '../components/CustomTabs';
import { useReportingReportsQuery } from '../hooks/queries/useReportingQuery';
import { getInOneDayDate, getOneDayAgoDate, getTomorrowDate, isSameDayOrAfter, isSameDayOrBefore } from '../utils/dates';
import { LinearProgress } from '@mui/material';
import CalendarRangeModal from '../components/modals/CalendarRangeModal';
import ReportingReports from '../components/tables/reporting/ReportingReports';
import ReportingReportsByUser from '../components/tables/reporting/ReportingReportsByUser';
import ReportingNeeds from '../components/tables/reporting/ReportingNeeds';

const ReportingPage: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const { t } = useTranslation();
    const [firstDate, setFirstDate] = useState(new Date(getItemFromLocalStorage(localStorageKeys.REPORTINGSTARTDATE) || new Date()));
    const [secondDate, setSecondDate] = useState(new Date(getItemFromLocalStorage(localStorageKeys.REPORTINGENDDATE) || getTomorrowDate()));
    const [showCalendarModal, setShowCalendarModal] = useState(false);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
        storeItemToLocalStorage(localStorageKeys.ADMINTAB, newValue.toString());
    };

    const handleFirstDateChange = (newDate: string) => {
        const tmpDate = new Date(newDate);
        if (!isSameDayOrBefore(tmpDate, secondDate)) return;
        setFirstDate(tmpDate);
        storeItemToLocalStorage(localStorageKeys.REPORTINGSTARTDATE, newDate);
    };

    const handleSecondDateChange = (newDate: string) => {
        const tmpDate = new Date(newDate);
        if (!isSameDayOrAfter(tmpDate, firstDate)) return;
        setSecondDate(tmpDate);
        storeItemToLocalStorage(localStorageKeys.REPORTINGENDDATE, newDate);
    };

    useEffect(() => {
        const tab = getItemFromLocalStorage(localStorageKeys.ADMINTAB);
        setSelectedTab(parseInt(tab || '0'));
    }, []);

    return (
        <div>
            <Header
                title={t('reporting') + ' (' + t('beta') + ')'}
                isSecondDateVisible
                date={firstDate}
                secondDate={secondDate}
                shortDate
                shortSecondDate
                onLeftArrowClick={() => handleFirstDateChange(getOneDayAgoDate(firstDate).toISOString())}
                onRightArrowClick={() => handleFirstDateChange(getInOneDayDate(firstDate).toISOString())}
                onSecondLeftArrowClick={() => handleSecondDateChange(getOneDayAgoDate(secondDate).toISOString())}
                onSecondRightArrowClick={() => handleSecondDateChange(getInOneDayDate(secondDate).toISOString())}
            >
                <Icon
                    name="calendarMonth"
                    onClick={() => setShowCalendarModal(true)}
                    title={capitalizeFirstLetter(t('calendar'))}
                    color="white"
                    classes={{ iconButton: 'pad-10px-imp' }}
                    dark
                />
                <div className="divider" />
            </Header>
            <div className="pad-l-15px pad-r-15px">
                <CustomTabs selectedTab={selectedTab} handleTabChange={handleTabChange} tabLabels={[t('reports'), t('report'), t('needs')]} />
                {selectedTab === 0 && <ReportingReports startDate={firstDate} endDate={secondDate} />}
                {selectedTab === 1 && <ReportingReportsByUser startDate={firstDate} endDate={secondDate} />}
                {selectedTab === 2 && <ReportingNeeds startDate={firstDate} endDate={secondDate} />}
            </div>

            {showCalendarModal && (
                <CalendarRangeModal
                    startDate={firstDate}
                    endDate={secondDate}
                    setStartDate={handleFirstDateChange}
                    setEndDate={handleSecondDateChange}
                    open={showCalendarModal}
                    handleClose={() => setShowCalendarModal(false)}
                />
            )}
        </div>
    );
};

export default ReportingPage;
