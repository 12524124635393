import React from 'react';

interface LicensePlateProps {
    number: string;
    department?: string;
    regionIcon?: string;
}

const LicensePlate: React.FC<LicensePlateProps> = ({ number, department, regionIcon }) => {
    return (
        <div className="border-radius-5px border fx-r max-w-150px">
            <div className="bg-euro text-white fx-c fx-items-c pad-l-5px pad-r-5px font-w-700 ">
                <div className="country-code">F</div>
            </div>
            <div className="fx-r fx-fg-1 fx-justify-c">
                <div className="font-w-700  ">{number}</div>
            </div>
        </div>
    );
};

export default LicensePlate;
