import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';

interface DroppableCellProps {
    dropId: { lineId: string }; // Mise à jour ici
    moveCell: (fromLineId: string, fromItemId: string, toLineId: string) => void;
    children?: React.ReactNode;
    itemType: string;
    disabled?: boolean;
}

const DroppableCell: React.FC<DroppableCellProps> = ({ dropId, moveCell, children, itemType, disabled = false }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: itemType,
        drop: (item: { dragIds: { lineId: string; itemId: string } }) => {
            if (!disabled) {
                const { lineId: fromLineId, itemId: fromItemId } = item.dragIds;
                const { lineId: toLineId } = dropId;
                moveCell(fromLineId, fromItemId, toLineId);
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
        },
    });
    drop(ref);

    return (
        <div
            className="droppable"
            ref={ref}
            style={{ backgroundColor: isOver ? 'gray' : undefined, borderRadius: 5, boxShadow: isOver ? '0 0 0 3px gray' : undefined }}
        >
            {children}
        </div>
    );
};

export default DroppableCell;
