import React, { useState } from 'react';
import { capitalizeFirstLetter } from '../../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { DailyTruckRide } from '../../../services/apiSLTP/dailyReports/dailyTrucksRides';
import { DataItem } from '../../../services/apiSLTP/activities';
import {
    getMaterialStateInfos,
    getStateInfos,
    getTruckModeInfos,
    materialState,
    permissionLevels,
    reportState,
    truckRideActivityTypeId,
} from '../../../utils/enums';
import { getMaterialStateColor, getStateColor } from '../../../utils/colors';
import Icon from '../../Icon';
import { LoadNature } from '../../../services/apiSLTP/materials/loadNatures';
import TrucksRidesRow from './TrucksRidesRows';
import { updateActivity } from '../../../services/apiSLTP/planning';
import { updateReportState } from '../../../services/apiSLTP/dailyReports/reports';
import SiteCell from '../../cells/SiteCell';
import StateCell from '../../cells/StateCell';
import ColorBar from '../../ColorBar';
import CustomToggle from '../../CustomToggle';
import TruckIcon from '../../TruckIcon';
import { Truck } from '../../../services/apiSLTP/materials/trucks';
import MachinesCell from '../../cells/MachinesCell';
import ToolsCell from '../../cells/ToolsCell';
import RentalsCell from '../../cells/RentalsCell';
import { useAuth } from '../../../hooks/useAuth';
import TempWorkersCell from '../../cells/TempWorkersCell';
import SubContractorsCell from '../../cells/SubContractorsCell';
import LicensePlate from '../../LicensePlate';

interface ActivityWithRidesRowProps {
    dailyTrucksRides: DailyTruckRide[];
    activities: DataItem[];
    activity: DataItem;
    refetch: () => void;
    trucks: Truck[];
    index: number;
    currentDate: Date;
}

const ActivityWithRidesRow: React.FC<ActivityWithRidesRowProps> = ({
    dailyTrucksRides,
    activities,
    activity,
    refetch,
    trucks,
    currentDate,
    index,
}) => {
    const { t } = useTranslation();
    const { user } = useAuth();

    const [isExpandable, setIsExpandable] = useState(false);

    const sortedDailyTrucksRides = () => {
        return dailyTrucksRides
            .filter((truckRide) => truckRide.activityId === activity._id)
            .sort((a, b) => {
                const timeA = new Date(a.time || '');
                const timeB = new Date(b.time || '');

                if (timeA < timeB) return -1;
                if (timeA > timeB) return 1;

                return 0;
            });
    };

    const formatTime = (time: string | undefined) => {
        if (time === undefined) return '';
        const date = new Date(time);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const handleActivityUnlock = async (activityId: string, reportId: string) => {
        await updateActivity(activityId, { activityState: reportState.REPORT });
        await updateReportState(reportId, reportState.REPORT);
        refetch();
    };

    return (
        <React.Fragment key={activity._id}>
            <tr
                key={activity._id}
                className="row"
                onClick={() => {
                    if (!!dailyTrucksRides.filter((truckRide) => truckRide.activityId === activity._id).length) setIsExpandable(!isExpandable);
                }}
            >
                <td className="pos-sy left-0 pad-0-imp z-idx-2">
                    <ColorBar
                        backgroundColor={activity.siteId?.conducId?.color || 'black'}
                        isPaddingTop={!(index > 0 && activities[index - 1].siteId?._id === activity.siteId?._id)}
                        isPaddingBottom={!(index < activities.length - 1 && activities[index + 1].siteId?._id === activity.siteId?._id)}
                        title={
                            activity.siteId?.conducId?._id
                                ? capitalizeFirstLetter(activity.siteId?.conducId?.firstName) +
                                  ' ' +
                                  activity.siteId?.conducId?.lastName.toUpperCase()
                                : ''
                        }
                    />
                </td>
                <td className="pos-sy left-30px z-idx-2">
                    <div className="fx-r">
                        <Icon
                            name={isExpandable ? 'keyboardArrowDownSharp' : 'keyboardArrowRightSharp'}
                            disabled={!dailyTrucksRides.filter((truckRide) => truckRide.activityId === activity._id).length}
                        />
                        <SiteCell activities={activities} activity={activity} activityIndex={index} currentDate={currentDate} refetch={refetch} />
                    </div>
                </td>
                <td>
                    <StateCell
                        stateColor={getStateColor(activity.activityState)}
                        text={capitalizeFirstLetter(t(getStateInfos(activity.activityState)))}
                    />
                </td>
                <td className="pos-sy left-230px z-idx-2">
                    <div>
                        {capitalizeFirstLetter(activity.reportId.userId.firstName)} {activity.reportId.userId.lastName.toUpperCase()}
                        <b>
                            {!!dailyTrucksRides.filter((truckRide) => truckRide.activityId === activity._id).length &&
                                ` | ${dailyTrucksRides.filter((truckRide) => truckRide.activityId === activity._id).length} ${t('ride(s)')}`}
                        </b>
                    </div>
                </td>
                <td>{formatTime(activity.startTime)}</td>
                <td>{formatTime(activity.validationTime)}</td>
                <td>{formatTime(activity?.validationTimestamp)}</td>
                <td>
                    <div className="fx-r fx-justify-c">
                        {activity.activityTypeId._id !== truckRideActivityTypeId ? (
                            <CustomToggle
                                checked={activity.isTruckManager}
                                disabled
                                title={capitalizeFirstLetter(activity.isTruckManager ? t('yes') : t('no'))}
                            />
                        ) : (
                            <TruckIcon
                                type={
                                    (trucks.find((value) => value.userId?._id === activity.reportId?.userId?._id)?.truckTypeId.designation || '') as
                                        | 'Semie'
                                        | 'Ampliroll'
                                        | '8x4'
                                        | 'Porte-char'
                                        | ''
                                }
                            />
                        )}
                    </div>
                </td>
                <td>
                    <div>{activity.observationsPlanning}</div>
                </td>
                <td>
                    <div>{activity.observationsWorker}</div>
                </td>
                <td>
                    <MachinesCell activity={activity} activityIndex={index} activities={activities} disabled refetch={refetch} hiddenPlusIcon />
                </td>
                <td>
                    <ToolsCell activity={activity} activityIndex={index} activities={activities} disabled hiddenPlusIcon />
                </td>
                <td>
                    <RentalsCell activity={activity} activityIndex={index} activities={activities} disabled hiddenPlusIcon />
                </td>
                <td>
                    <TempWorkersCell activity={activity} activityIndex={index} activities={activities} disabled hiddenPlusIcon />
                </td>
                <td>
                    <SubContractorsCell activity={activity} activityIndex={index} activities={activities} disabled hiddenPlusIcon />
                </td>
                <td>
                    <div>{activity.activityTypeId.designation}</div>
                </td>
                {user && user?.profileId?.permsLevel <= permissionLevels.SUPERVISION && (
                    <td>
                        {(activity.activityState === reportState.REPORTFILLED || activity.reportId.reportState === reportState.REPORTFILLED) && (
                            <Icon
                                name="lock"
                                onClick={() => {
                                    handleActivityUnlock(activity._id, activity.reportId._id);
                                }}
                            />
                        )}
                    </td>
                )}
            </tr>
            {isExpandable && !!dailyTrucksRides.filter((truckRide) => truckRide.activityId === activity._id).length && (
                <tr>
                    <td colSpan={17}>
                        <table>
                            <thead>
                                <tr>
                                    <th>{capitalizeFirstLetter(t('company'))}</th>
                                    <th>{capitalizeFirstLetter(t('plateNumber'))}</th>
                                    <th>{capitalizeFirstLetter(t('driver'))}</th>
                                    <th>{capitalizeFirstLetter(t('type'))}</th>
                                    <th>{capitalizeFirstLetter(t('mode'))}</th>
                                    <th>
                                        <div className="source">{t('source: ')}</div>
                                        <div>{t('loadNature')}</div>
                                    </th>
                                    <th>
                                        <div className="source">{t('source: ')}</div>
                                        <div>{t('place')}</div>
                                    </th>
                                    <th>
                                        <div className="source">{t('source: ')}</div>
                                        <div>{t('dump')}</div>
                                    </th>
                                    <th>
                                        <div className="source">{t('source: ')}</div>
                                        <div>{t('tonnage')}</div>
                                    </th>
                                    <th>
                                        <div className="source">{t('source: ')}</div>
                                        <div>{t('hour')}</div>
                                    </th>
                                    <th>{capitalizeFirstLetter(t('siteHour'))}</th>
                                    <th>
                                        <div className="dest">{t('destination: ')}</div>
                                        <div>{t('loadNature')}</div>
                                    </th>
                                    <th>
                                        <div className="dest">{t('destination: ')}</div>
                                        <div>{t('place')}</div>
                                    </th>
                                    <th>
                                        <div className="dest">{t('destination: ')}</div>
                                        <div>{t('dump')}</div>
                                    </th>
                                    <th>
                                        <div className="dest">{t('destination: ')}</div>
                                        <div>{t('tonnage')}</div>
                                    </th>
                                    <th>
                                        <div className="dest">{t('destination: ')}</div>
                                        <div>{t('hour')}</div>
                                    </th>
                                    <th>{capitalizeFirstLetter(t('onSiteBackTime'))}</th>
                                    <th>{capitalizeFirstLetter(t('transfers'))}</th>
                                    <th>{capitalizeFirstLetter(t('observations'))}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedDailyTrucksRides().map((truckRide) => {
                                    return (
                                        <tr key={truckRide._id} className={`wrapper ${isExpandable ? 'open' : ''}`}>
                                            <td>{truckRide.truckId?.truckCompanyId?.designation}</td>
                                            <td>
                                                <LicensePlate number={truckRide.truckId?.plateNumber} />
                                            </td>
                                            <td>
                                                {capitalizeFirstLetter(truckRide.truckId.userId?.firstName) +
                                                    ' ' +
                                                    (truckRide.truckId.userId?.lastName.toUpperCase() || '')}
                                            </td>
                                            <td>{truckRide.truckId?.truckTypeId?.designation}</td>
                                            <td>{capitalizeFirstLetter(t(getTruckModeInfos(truckRide?.mode)))}</td>
                                            <td>{truckRide.departureLoadNatureId?.designation}</td>
                                            <td>{truckRide.departureSiteId?.name || truckRide.departureQuarryId?.designation || ''}</td>
                                            <td>{truckRide.departureDumpId?.designation || ''}</td>
                                            <td>{truckRide.departureTonnage || ''}</td>
                                            <td>{formatTime(truckRide.departureTime)}</td>
                                            <td>{formatTime(truckRide.time)}</td>
                                            <td>{truckRide.arrivalLoadNatureId?.designation}</td>
                                            <td>{truckRide.arrivalSiteId?.name || truckRide.arrivalQuarryId?.designation || ''}</td>
                                            <td>{truckRide.arrivalDumpId?.designation || ''}</td>
                                            <td>{truckRide.arrivalTonnage || ''}</td>
                                            <td>{formatTime(truckRide.arrivalTime)}</td>
                                            <td>{formatTime(truckRide.secondTime)}</td>
                                            <td>
                                                {truckRide.transfers.map((transfer, transferIdx) => (
                                                    <div
                                                        className="border-radius-5px pad-l-5px fx-c"
                                                        style={{
                                                            backgroundColor: getMaterialStateColor(transfer.state),
                                                            marginTop: transferIdx !== 0 ? 5 : 0,
                                                        }}
                                                        title={
                                                            (transfer.machineId?.designation ||
                                                                transfer.toolId?.designation ||
                                                                `${transfer.rentalName} ${transfer.rentalCompanyId?.designation}`) +
                                                            ` ${t(getMaterialStateInfos(transfer.state))} ` +
                                                            (transfer.siteId?.name || transfer.quarryId?.designation)
                                                        }
                                                    >
                                                        <div>
                                                            {transfer.machineId?.designation ||
                                                                transfer.toolId?.designation ||
                                                                `${transfer.rentalName} ${transfer.rentalCompanyId?.designation}`}
                                                        </div>
                                                        <div>
                                                            <b>{transfer.state === materialState.NEW ? t('from') + ' : ' : t('to') + ' : '}</b>
                                                            {transfer.siteId?.name || transfer.quarryId?.designation}
                                                        </div>
                                                    </div>
                                                ))}
                                            </td>
                                            <td>{truckRide.observations || ''}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </td>
                </tr>
            )}
        </React.Fragment>
    );
};

export default ActivityWithRidesRow;
