import React, { useEffect, useState } from 'react';
import { Modal, Button } from '@mui/material';
import DropDown from '../DropDown';
import { getTempWorkerTypes, createDailyTempWorker } from '../../services/apiSLTP/planning';
import { DataItem, updateDailyTempWorker } from '../../services/apiSLTP/activities';
import ModalBody from './ModalBody';
import { useTranslation } from 'react-i18next';
import CustomTextInput from '../CustomTextInput';
import NumberPicker from '../NumberPicker';
import { capitalizeFirstLetter } from '../../utils/capitalize';

interface ModalProps {
    open: boolean;
    handleClose: () => void;
    activity: DataItem;
    activityIndex: number;
    refetch: () => void;
    tempWorkerId: string;
}

const CreateDailyTempWorkerModal: React.FC<ModalProps> = ({ open, handleClose, activity, activityIndex, refetch, tempWorkerId }) => {
    const [tempWorkersTypes, setTempWorkersTypes] = useState<{ label: string; value: string }[]>([]);
    const [selectedTempWorkerType, setSelectedTempWorkerType] = useState({ label: '', value: '' });
    const [currentTempWorkerId, setCurrentTempWorkerId] = useState(tempWorkerId);
    const [tempWorkerName, setTempWorkerName] = useState('');
    const [durationHours, setDurationHours] = useState(7);
    const { t } = useTranslation();

    const fetchTempWorkersTypes = async () => {
        const response = await getTempWorkerTypes();
        setTempWorkersTypes(
            response.data.map((value) => {
                return { label: value.designation, value: value._id };
            })
        );
    };

    const postDailyTempWorker = async () => {
        const response = await createDailyTempWorker({
            activityId: activity._id,
            tempWorkerTypeId: selectedTempWorkerType.value,
            durationHours: durationHours,
            tempWorkerName: tempWorkerName,
        });
        setCurrentTempWorkerId(response.data._id);
        refetch();
    };

    const patchDailyTempWorker = async () => {
        const response = await updateDailyTempWorker(currentTempWorkerId, {
            tempWorkerTypeId: selectedTempWorkerType.value,
            durationHours: durationHours,
            tempWorkerName: tempWorkerName,
        });
        refetch();
    };

    useEffect(() => {
        fetchTempWorkersTypes();

        const dailyTempWorker = activity.dailyTempWorkers.find((value) => value._id === tempWorkerId);
        if (dailyTempWorker) {
            setSelectedTempWorkerType({
                label: dailyTempWorker.tempWorkerTypeId?.designation || '',
                value: dailyTempWorker.tempWorkerTypeId?._id || '',
            });
            setTempWorkerName(dailyTempWorker.tempWorkerName || '');
            setDurationHours(dailyTempWorker.durationHours ?? 7);
        }
    }, []);

    return (
        <ModalBody
            closeText={t('cancel')}
            title={t('tempWorker')}
            subTitle={!!currentTempWorkerId.length ? t('update') : t('add')}
            okText={t('valider')}
            open={open}
            handleClose={handleClose}
            handleOk={() => {
                !currentTempWorkerId.length ? postDailyTempWorker() : patchDailyTempWorker();
            }}
        >
            <div className="fx-c">
                <DropDown
                    selectedItem={selectedTempWorkerType}
                    items={tempWorkersTypes}
                    handleChange={(event, value) => setSelectedTempWorkerType({ value: value?.value || '', label: value?.label || '' })}
                    inputLabel={t('company')}
                />
                <CustomTextInput value={tempWorkerName} onChange={(text) => setTempWorkerName(text)} label={t('tempWorker')} />
                <NumberPicker
                    min={0}
                    max={24}
                    title={capitalizeFirstLetter(t('durationHours'))}
                    initialValue={durationHours}
                    onChange={(value) => setDurationHours(value)}
                />
            </div>
        </ModalBody>
    );
};

export default CreateDailyTempWorkerModal;
