import React from 'react';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { DailyTempWorker } from '../../services/apiSLTP/planning';
import { DataItem } from '../../services/apiSLTP/activities';

interface TempWorkersTableProps {
    activities: DataItem[];
    universalFilter: string;
}

const TempWorkersTable: React.FC<TempWorkersTableProps> = ({ activities, universalFilter }) => {
    const { t } = useTranslation();

    const sortActivitiesByTempWorker = () => {
        return activities
            .map((activity) => activity.dailyTempWorkers)
            .flat(1)
            .sort((a, b) => {
                if (a.tempWorkerTypeId.designation > b.tempWorkerTypeId.designation) return 1;
                if (a.tempWorkerTypeId.designation < b.tempWorkerTypeId.designation) return -1;
                if ((a.tempWorkerName || '') > (b.tempWorkerName || '')) return 1;
                if ((a.tempWorkerName || '') < (b.tempWorkerName || '')) return -1;
                return 0;
            });
    };

    const getActivityForTempWorker = (activityId: string) => {
        return activities.find((activity) => activity._id === activityId);
    };

    return (
        <div className="table-container">
            <table id="csv-planning-obj">
                <thead>
                    <tr className="">
                        <th scope="col" className="w-200px pos-sy left-0 z-idx-4">
                            {capitalizeFirstLetter(t('company'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('person'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('duration') + ' (' + t('hours') + ')')}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('site'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('addBy'))}
                        </th>
                    </tr>
                </thead>
                <tbody id="fu-planning-obj">
                    {sortActivitiesByTempWorker().map((dailyTempWorker) => (
                        <tr key={dailyTempWorker._id} className="row">
                            <td className="pos-sy left-0 z-idx-2">{dailyTempWorker.tempWorkerTypeId.designation}</td>
                            <td>{dailyTempWorker.tempWorkerName}</td>
                            <td>{dailyTempWorker.durationHours}</td>
                            <td className="">{getActivityForTempWorker(dailyTempWorker.activityId)?.siteId.name}</td>
                            <td>
                                {getActivityForTempWorker(dailyTempWorker.activityId)?.reportId.userId.lastName.toUpperCase()}{' '}
                                {capitalizeFirstLetter(getActivityForTempWorker(dailyTempWorker.activityId)?.reportId.userId.firstName)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TempWorkersTable;
