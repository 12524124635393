import React, { useEffect, useMemo, useState } from 'react';
import { capitalizeFirstLetter } from '../../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { useReportingReportsByUserQuery, useReportingReportsQuery } from '../../../hooks/queries/useReportingQuery';
import { LinearProgress } from '@mui/material';
import {
    BarChart,
    Bar,
    Rectangle,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    CartesianAxis,
    Brush,
    ReferenceLine,
} from 'recharts';
import { getDaysDifference, removeDuplicateDates, sameDay } from '../../../utils/dates';
import ResponsiveCard from '../../ResponsiveCard';
import { useActivitiesByDatesQuery } from '../../../hooks/queries/useActivitiesQuery';
import { getStateInfos, reportState, stepModes, truckRideActivityTypeId } from '../../../utils/enums';
import Icon from '../../Icon';
import SubContractorsCell from '../../cells/SubContractorsCell';
import TempWorkersCell from '../../cells/TempWorkersCell';
import RentalsCell from '../../cells/RentalsCell';
import ToolsCell from '../../cells/ToolsCell';
import MachinesCell from '../../cells/MachinesCell';
import TruckIcon from '../../TruckIcon';
import CustomToggle from '../../CustomToggle';
import SiteCell from '../../cells/SiteCell';
import StateCell from '../../cells/StateCell';
import { getStateColor } from '../../../utils/colors';
import { useTrucksQuery } from '../../../hooks/queries/useTrucksQuery';

interface ReportingReportsProps {
    startDate: Date;
    endDate: Date;
}

const ReportingReports: React.FC<ReportingReportsProps> = ({ startDate, endDate }) => {
    const { t } = useTranslation();
    const { data: dataReportingReports, isLoading: isReportingReportsLoading } = useReportingReportsQuery({
        params: { startDate: startDate.toISOString(), endDate: endDate.toISOString() },
    });

    const [selectedUser, setSelectedUser] = useState<{ name: string; id: string } | null>(null);
    const [selectedDate, setSelectedDate] = useState('');

    const { data: dataReportingReportsByUser, isLoading: isReportingReportsByUserLoading } = useReportingReportsByUserQuery({
        params: { startDate: startDate.toISOString(), endDate: endDate.toISOString(), userId: selectedUser?.id || '' },
    });

    const { data: dataActivities, isLoading: isActivitiesLoading } = useActivitiesByDatesQuery({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        minState: reportState.REPORT,
        maxState: reportState.REPORT,
        stepMode: stepModes.REPORT,
        userId: selectedUser?.id || '',
    });

    const { data: trucksData, isLoading: trucksIsLoading, isError: trucksIsError, isSuccess: trucksIsSuccess } = useTrucksQuery({});

    const formatTime = (time: string | undefined) => {
        if (time === undefined) return '';
        const date = new Date(time);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const processedData = useMemo(() => {
        return dataReportingReports
            ?.filter((reporting) => !reporting.user.isSpecial)
            .sort((a, b) => (a.user.firstName + a.user.lastName).localeCompare(b.user.firstName + b.user.lastName))
            .map((reporting) => ({
                name: capitalizeFirstLetter(reporting.user.firstName) + ' ' + reporting.user.lastName.toUpperCase(),
                username: reporting.user.username.toUpperCase(),
                reportsSent: reporting.nbReportsSent,
                reportsNotSent: reporting.nbReports - reporting.nbReportsSent,
                reports: reporting.nbReports,
                id: reporting.user._id,
                nbActivitiesNotSent: reporting.nbActivities - reporting.nbActivitiesSent,
                nbActivitiesSent: reporting.nbActivitiesSent,
                nbActivities: reporting.nbActivities,
            }));
    }, [dataReportingReports]);

    return (
        <div className="table-container bg-white ">
            <div className="fx-r bg-black pad-15px"></div>
            <div className="loading-bar">{isReportingReportsLoading && <LinearProgress color="inherit" />}</div>
            <div className="dashboard">
                <ResponsiveCard title="Rapports" heightSize="small" widthSize="xxlarge">
                    <ResponsiveContainer>
                        <BarChart
                            data={processedData}
                            onClick={(nextState, event) => {
                                if (nextState.activePayload?.at(0).payload.id) {
                                    setSelectedUser({
                                        id: nextState.activePayload?.at(0).payload.id || '',
                                        name: nextState.activePayload?.at(0).payload.name || '',
                                    });
                                    setSelectedDate('');
                                }
                            }}
                        >
                            <XAxis dataKey={'username'} name={'name'} angle={-90} dy={10} dx={-4} tickLine={false} height={40} />
                            <YAxis dataKey={'reports'} domain={[0, getDaysDifference(startDate, endDate) + 1]} axisLine={false} tickLine={false} />
                            <Tooltip
                                labelFormatter={(label, payload) => {
                                    const dataPoint = payload?.[0]?.payload;
                                    return dataPoint?.name || label;
                                }}
                            />
                            <Legend verticalAlign="top" />
                            <CartesianGrid vertical={false} />
                            {processedData && processedData?.length > 30 && <Brush dataKey="username" height={20} stroke="#8884d8" />}
                            <Bar
                                dataKey="reportsSent"
                                fill="#55AA55"
                                stackId={'a'}
                                name={capitalizeFirstLetter(t('reportsSent'))}
                                activeBar={<Rectangle fill="purple" stroke="#55AA55" />}
                            />
                            <Bar
                                dataKey="reportsNotSent"
                                fill="orange"
                                stackId={'a'}
                                name={capitalizeFirstLetter(t('reportsNotSent'))}
                                activeBar={<Rectangle fill="pink" stroke="orange" />}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </ResponsiveCard>
            </div>
            <div className="loading-bar">{isActivitiesLoading && <LinearProgress color="inherit" />}</div>

            {!!dataReportingReportsByUser?.length && (
                <div className="dashboard">
                    <ResponsiveCard title={capitalizeFirstLetter(t('activities')) + ' : ' + selectedUser?.name} heightSize="small" widthSize="medium">
                        <ResponsiveContainer>
                            <BarChart
                                data={dataReportingReportsByUser.map((report) => ({
                                    ...report,
                                    date: new Date(report.date)
                                        .toLocaleDateString(t('localeDate'), {
                                            month: 'numeric',
                                            day: 'numeric',
                                        })
                                        .replace(/\b\w/g, (char) => char.toUpperCase()),
                                    nbActivitiesNotSent: report.nbActivities - report.nbActivitiesSent,
                                    isoDate: report.date,
                                }))}
                                onClick={(nextState, event) => {
                                    if (nextState.activePayload?.at(0).payload.isoDate) {
                                        setSelectedDate(nextState.activePayload?.at(0).payload.isoDate || '');
                                    }
                                }}
                            >
                                <XAxis dataKey="date" />
                                <YAxis dataKey={'nbActivities'} axisLine={false} tickLine={false} />
                                <Tooltip />
                                <Legend />
                                <CartesianGrid vertical={false} />
                                <Bar
                                    dataKey="nbActivitiesSent"
                                    name={capitalizeFirstLetter(t('activitiesSent'))}
                                    fill="#55AA55"
                                    stackId={'b'}
                                    activeBar={<Rectangle fill="purple" stroke="#55AA55" />}
                                />
                                <Bar
                                    dataKey="nbActivitiesNotSent"
                                    name={capitalizeFirstLetter(t('activitiesNotSent'))}
                                    fill="orange"
                                    stackId={'b'}
                                    activeBar={<Rectangle fill="pink" stroke="orange" />}
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    </ResponsiveCard>
                    {!!selectedDate.length &&
                        !!dataActivities?.filter((value) => sameDay(new Date(value.reportId.date), new Date(selectedDate))).length && (
                            <ResponsiveCard
                                title={
                                    capitalizeFirstLetter(t('activitiesNotValidated')) +
                                    ' : ' +
                                    new Date(selectedDate).toLocaleDateString(t('localeDate'), {
                                        month: 'numeric',
                                        day: 'numeric',
                                    })
                                }
                                heightSize="small"
                                widthSize="medium"
                            >
                                <div className="table-container">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th scope="col" className="w-100px">
                                                    {capitalizeFirstLetter(t('date'))}
                                                </th>
                                                <th scope="col" className="w-200px">
                                                    {capitalizeFirstLetter(t('site'))}
                                                </th>

                                                <th scope="col" className="w-300px">
                                                    {capitalizeFirstLetter(t('observations'))}
                                                </th>

                                                <th scope="col" className="w-200px">
                                                    {capitalizeFirstLetter(t('activity'))}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody id="fu-planning-obj">
                                            {dataActivities
                                                ?.filter((value) => sameDay(new Date(value.reportId.date), new Date(selectedDate)))
                                                .map((activity, index, array) => {
                                                    return (
                                                        <tr key={activity._id}>
                                                            <td>
                                                                {new Date(activity.reportId.date).toLocaleDateString(t('localeDate'), {
                                                                    year: 'numeric',
                                                                    month: 'numeric',
                                                                    day: 'numeric',
                                                                })}
                                                            </td>
                                                            <td className="pos-sy">
                                                                <SiteCell activities={array} activity={activity} activityIndex={index} displayAll />
                                                            </td>

                                                            <td>
                                                                <div>{activity.observationsWorker}</div>
                                                            </td>

                                                            <td>
                                                                <div>{activity.activityTypeId.designation}</div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </ResponsiveCard>
                        )}
                </div>
            )}
        </div>
    );
};

export default ReportingReports;
