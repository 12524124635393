import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { permissionLevels } from '../utils/enums';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../utils/capitalize';
import Icon from '../components/Icon';
import Footer from '../components/Footer';
import GhostEmoji from '../components/GhostMoji';
import { getTodayPeriod } from '../utils/dates';
import { Divider, ListItemIcon } from '@mui/material';
import { logout } from '../services/apiSLTP/auth';

const NavBar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const { isAuthenticated, user, logout: removeConnection } = useAuth();
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const pages = [
        { name: t('needs'), link: '/needs', permsLevel: permissionLevels.CONDUC },
        { name: t('planning'), link: '/planning', permsLevel: permissionLevels.OBSERVERS },
        { name: t('supervision'), link: '/supervision', permsLevel: permissionLevels.CONDUC },
        { name: t('reporting'), link: '/reporting', permsLevel: permissionLevels.SUPERVISION },
        { name: t('admin'), link: '/admin', permsLevel: permissionLevels.PLANNING },
    ];
    const settings = [{ name: 'Profile', link: '/profile' }];

    const getProfileText = (permsLvl: number) => {
        switch (permsLvl) {
            case permissionLevels.ADMIN:
                return t('admin');
            case permissionLevels.SUPERVISION:
                return t('supervision');
            case permissionLevels.PLANNING:
                return t('planning');
            case permissionLevels.CONDUC:
                return t('conduc');
            case permissionLevels.OBSERVERS:
                return t('observers');
            case permissionLevels.TERRAIN:
                return t('terrain');
            case permissionLevels.CHAUFFEUR:
                return t('chauffeur');
            default:
                return t('');
        }
    };

    const getLogoByPeriod = () => {
        const period = getTodayPeriod(new Date());

        if (period === 'normal') return require('../assets/images/my-sltp-transp.png');
        if (period === 'halloween') return require('../assets/images/my-sltp-transp-halloween.png');
        if (period === 'christmas') return require('../assets/images/my-sltp-transp-christmas.png');
        if (period === 'newYear') return require('../assets/images/my-sltp-transp-newyear.png');

        return require('../assets/images/my-sltp-transp.png');
    };

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        await logout();
        removeConnection();
        navigate('/login');
    };

    return (
        <div hidden={!isAuthenticated}>
            <div
                style={{
                    backgroundColor: 'black',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    padding: 5,
                }}
            >
                <div className="hidden-xs " style={{ flexGrow: 1 / 3 }}>
                    <a href="/" style={{ marginLeft: 10 }} className="fx-r fx-items-c">
                        <img height={29} src={getLogoByPeriod()} />
                    </a>
                </div>

                <div className="display-xs fx-r fx-items-c fx-justify-fs" style={{ flexGrow: 1 }}>
                    <div style={{ marginLeft: 20, marginRight: 20, alignItems: 'center', display: 'flex' }}>
                        <Icon name="menu" onClick={handleOpenNavMenu} dark />
                    </div>
                    <a href="/" className="fx-r fx-items-c">
                        <img height={29} src={require('../assets/images/my-s-transp.png')} />
                    </a>
                    {isAuthenticated && (
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map(
                                (page) =>
                                    user &&
                                    page.permsLevel >= user.profileId.permsLevel && (
                                        <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                            <a
                                                href={page.link}
                                                style={{
                                                    fontWeight: location.pathname === page.link ? 'bold' : 'normal',
                                                    textDecoration: 'none',
                                                    color: '#000',
                                                }}
                                            >
                                                {capitalizeFirstLetter(page.name)}
                                            </a>
                                        </MenuItem>
                                    )
                            )}
                        </Menu>
                    )}
                </div>

                <div className="hidden-xs fx-justify-c" style={{ flexGrow: 1 / 3 }}>
                    {isAuthenticated && (
                        <>
                            {pages.map(
                                (page) =>
                                    user &&
                                    page.permsLevel >= user?.profileId.permsLevel && (
                                        <Button
                                            key={page.name}
                                            component={Link}
                                            to={page.link}
                                            onClick={handleCloseNavMenu}
                                            sx={{
                                                color: location.pathname === page.link ? 'white' : 'gray',
                                                display: 'block',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {page.name}
                                        </Button>
                                    )
                            )}
                        </>
                    )}
                </div>
                <div className="fx-r fx-justify-fe" style={{ flexGrow: 1 / 3, paddingRight: 5 }}>
                    <div className="hidden-xs">
                        {user && (
                            <div className="fx-r fx-items-c text-white" style={{ paddingRight: 10, fontWeight: 'bold' }}>
                                {capitalizeFirstLetter(user?.firstName)} {user?.lastName.toUpperCase()}
                            </div>
                        )}
                    </div>
                    <IconButton onClick={handleClick} sx={{ p: 0, m: 0 }}>
                        <Avatar alt="Profile" style={{ border: '3px solid ' + user?.color, borderRadius: 50, backgroundColor: '#000' }}></Avatar>
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        slotProps={{
                            paper: {
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&::before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={isAuthenticated ? () => window.location.replace('/profile') : () => window.location.replace('/login')}>
                            <Avatar style={{ backgroundColor: '#000'}} />
                            {capitalizeFirstLetter(t('myProfile'))}
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                                <Icon name="logout" color="crimson" />
                            </ListItemIcon>
                            {capitalizeFirstLetter(t('logout'))}
                        </MenuItem>
                    </Menu>
                </div>
            </div>
        </div>
    );
};
export default NavBar;
