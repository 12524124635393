import React from 'react';
import { Modal } from '@mui/material';
import CustomButton from '../CustomButton';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import Icon from '../Icon';

interface ModalProps {
    title?: string;
    subTitle?: string;
    open: boolean;
    handleClose: () => void;
    handleOk?: () => void;
    handleThird?: () => void;
    okText: string;
    closeText?: string;
    thirdButton?: boolean;
    thirdText?: string;
    children?: React.ReactNode;
    disabledOk?: boolean;
    disabledThird?: boolean;
    height?: string | number;
    width?: string | number;
    okCanClose?: boolean;
    isOkVisible?: boolean;
}

const ModalBody: React.FC<ModalProps> = ({
    title,
    open,
    handleClose,
    handleOk = () => {},
    handleThird = () => {},
    okText,
    closeText,
    children,
    thirdButton = false,
    thirdText = '',
    disabledOk = false,
    disabledThird = false,
    isOkVisible = true,
    height = 'auto',
    width = 'auto',
    okCanClose = true,
    subTitle,
}) => {
    return (
        <Modal open={open} onClose={handleClose}>
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: '80%',
                    backgroundColor: '#ffffff',
                    height,
                    width,
                    borderRadius: 6,
                    outline: 'none',
                    maxHeight: '80%',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid rgba(0, 0, 0, 0.5)'
                }}
            >
                <div
                    className=" bg-black fx-r fx-justify-sb fx-items-c pad-l-10px pad-r-10px text-white "
                    style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
                >
                    <h2 className="xlFont m-t-10px m-b-10px">{capitalizeFirstLetter(title)}</h2>
                    <div className="pad-l-20px">
                        <Icon name="close" onClick={handleClose} dark />
                    </div>
                </div>
                {subTitle && (
                    <div className=" bg-lightblack">
                        <div className="fx-r fx-justify-sb fx-items-c pad-l-10px pad-r-10px text-white ">
                            <h3 className="mFont m-t-5px m-b-5px">{capitalizeFirstLetter(subTitle)}</h3>
                        </div>
                    </div>
                )}

                <div
                    className="pad-l-10px pad-r-10px pad-t-10px fx-c"
                    style={{
                        maxHeight: '50%',
                        overflowY: 'auto',
                    }}
                >
                    {children}
                </div>

                <div className="fx-r fx-justify-fe fx-w  m-15px">
                    {/*<div className="pad-r-15px pad-l-15px pad-t-15px">
                        <CustomButton onClick={handleClose}>{closeText}</CustomButton>
                    </div>*/}
                    {isOkVisible && (
                        <CustomButton
                            disabled={disabledOk}
                            onClick={() => {
                                handleOk();
                                if (okCanClose) handleClose();
                            }}
                            color="#43A047"
                            textColor="#fff"
                        >
                            {okText}
                        </CustomButton>
                    )}

                    {thirdButton && (
                        <div className="pad-r-15px">
                            <CustomButton
                                disabled={disabledThird}
                                onClick={() => {
                                    handleThird();
                                    handleClose();
                                }}
                            >
                                {thirdText}
                            </CustomButton>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default ModalBody;
