import { useMutation, MutationFunction, MutationOptions } from 'react-query';
import { UpdateDailyTruckPlannedBody, DailyTruckPlannedResponse, updateDailyTruckPlanned, CreateDailyTruckPlannedBody, createDailyTruckPlanned, updateDailyTruckPlannedRank, UpdateDailyTruckRankBody, UpdateDailyTruckRankUpDownBody, updateDailyTruckPlannedRankUpDown } from '../../services/apiSLTP/dailyReports/dailyTrucksPlanned';

type UseUpdateDailyTruckPlannedMutationOptions = MutationOptions<DailyTruckPlannedResponse, Error, { DailyTruckPlannedId: string; data: UpdateDailyTruckPlannedBody }>;

export const useUpdateDailyTruckPlannedMutation = (options?: UseUpdateDailyTruckPlannedMutationOptions) => {
    const DailyTruckPlannedMutation: MutationFunction<DailyTruckPlannedResponse, { DailyTruckPlannedId: string; data: UpdateDailyTruckPlannedBody }> = async ({ DailyTruckPlannedId, data }) => {
        try {
            const response: DailyTruckPlannedResponse = await updateDailyTruckPlanned(DailyTruckPlannedId, data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during DailyTruckPlanned');
        }
    };

    return useMutation(DailyTruckPlannedMutation, options);
};

type UseCreateDailyTruckPlannedMutationOptions = MutationOptions<DailyTruckPlannedResponse, Error, CreateDailyTruckPlannedBody>;

export const useCreateDailyTruckPlannedMutation = (options?: UseCreateDailyTruckPlannedMutationOptions) => {
    const DailyTruckPlannedMutation: MutationFunction<DailyTruckPlannedResponse, CreateDailyTruckPlannedBody> = async (data) => {
        try {
            const response: DailyTruckPlannedResponse = await createDailyTruckPlanned(data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during DailyTruckPlanned');
        }
    };

    return useMutation(DailyTruckPlannedMutation, options);
};


type UseUpdateDailyTruckPlannedRankMutationOptions = MutationOptions<DailyTruckPlannedResponse, Error, { id: string; body: UpdateDailyTruckRankBody }>;

export const useUpdateDailyTruckPlannedRankMutation = (options?: UseUpdateDailyTruckPlannedRankMutationOptions) => {
    const DailyTruckPlannedRankMutation: MutationFunction<DailyTruckPlannedResponse, { id: string; body: UpdateDailyTruckRankBody }> = async ({ id, body }) => {
        try {
            const response: DailyTruckPlannedResponse = await updateDailyTruckPlannedRank(id, body);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during DailyTruckPlanned');
        }
    };

    return useMutation(DailyTruckPlannedRankMutation, options);
};

type UseUpdateDailyTruckPlannedRankUpDownMutationOptions = MutationOptions<DailyTruckPlannedResponse, Error, { id: string; body: UpdateDailyTruckRankUpDownBody }>;

export const useUpdateDailyTruckPlannedRankUpDownMutation = (options?: UseUpdateDailyTruckPlannedRankUpDownMutationOptions) => {
    const DailyTruckPlannedRankMutation: MutationFunction<DailyTruckPlannedResponse, { id: string; body: UpdateDailyTruckRankUpDownBody }> = async ({ id, body }) => {
        try {
            const response: DailyTruckPlannedResponse = await updateDailyTruckPlannedRankUpDown(id, body);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during DailyTruckPlanned');
        }
    };

    return useMutation(DailyTruckPlannedRankMutation, options);
};
