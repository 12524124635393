import React, { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from '../utils/capitalize';
import { useTranslation } from 'react-i18next';

const NumberPicker: React.FC<{
    min?: number;
    max?: number;
    step?: number;
    initialValue?: number;
    title?: string;
    onChange?: (value: number) => void;
}> = ({ min = 0, max = 100, step = 1, initialValue = 0, onChange, title }) => {
    const [value, setValue] = useState<number>(initialValue);
    const { t } = useTranslation();

    const handleIncrement = () => {
        setValue((prev) => {
            const newValue = Math.min(prev + step, max);
            onChange?.(newValue);
            return newValue;
        });
    };

    const handleDecrement = () => {
        setValue((prev) => {
            const newValue = Math.max(prev - step, min);
            onChange?.(newValue);
            return newValue;
        });
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = parseInt(e.target.value, 10);
        if (!isNaN(inputValue)) {
            const clampedValue = Math.min(Math.max(inputValue, min), max);
            setValue(clampedValue);
            onChange?.(clampedValue);
        } else {
            setValue(min);
        }
    };

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <div className="fx-c w-300px pad-5px">
            <label className='sFont pad-l-15px'>{title}</label>
            <div
                className="fx-r h-25px fx-r fx-items-c"
                style={{
                    gap: 5,
                }}
            >
                <button
                    style={{
                        backgroundColor: '#000',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        fontSize: '16px',
                        width: 30,
                    }}
                    onClick={handleDecrement}
                >
                    -
                </button>
                <input
                    type="number"
                    value={value}
                    onChange={handleInputChange}
                    style={{
                        width: '60px',
                        textAlign: 'center',
                        fontSize: '16px',
                        border: 'none',
                        borderRadius: '4px',
                        margin: 0,
                        padding: 0,
                    }}
                />
                <button
                    style={{
                        backgroundColor: '#000',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        fontSize: '16px',
                        width: 30,
                    }}
                    onClick={handleIncrement}
                >
                    +
                </button>
            </div>
        </div>
    );
};

export default NumberPicker;
