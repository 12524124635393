import React, { useEffect, useMemo, useState } from 'react';
import { capitalizeFirstLetter } from '../../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { useReportingNeedsValidationTimeQuery } from '../../../hooks/queries/useReportingQuery';
import { LinearProgress } from '@mui/material';
import {
    BarChart,
    Bar,
    Rectangle,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    CartesianAxis,
    Brush,
    ReferenceLine,
} from 'recharts';
import { getDaysDifference, removeDuplicateDates, sameDay } from '../../../utils/dates';
import ResponsiveCard from '../../ResponsiveCard';
import { NeedsValidationTimeData } from '../../../services/apiSLTP/reporting/reporting';

interface ReportingNeedsProps {
    startDate: Date;
    endDate: Date;
}

const ReportingNeeds: React.FC<ReportingNeedsProps> = ({ startDate, endDate }) => {
    const { t } = useTranslation();
    const { data: dataReportingNeeds, isLoading: isReportingNeedsLoading } = useReportingNeedsValidationTimeQuery({
        params: { startDate: startDate.toISOString(), endDate: endDate.toISOString() },
    });

    const formatTime = (time: string | undefined) => {
        if (time === undefined) return '';
        const date = new Date(time);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const processDataForChart = (data: NeedsValidationTimeData[]) => {
        const groupedData: Record<string, Record<string, { before11: number; between11and12: number; after12: number }>> = {};

        data.forEach((currentItem) => {
            const userId = currentItem.needSubmitValidationUserId;
            console.log(currentItem.needSubmitValidationTimeStamp)
            const day = new Date(currentItem.needSubmitValidationTimeStamp).toLocaleDateString();
            const hour = new Date(currentItem.needSubmitValidationTimeStamp).getHours();

            if (!groupedData[userId._id]) {
                groupedData[userId._id] = {};
            }
            if (!groupedData[userId._id][day]) {
                groupedData[userId._id][day] = { before11: 0, between11and12: 0, after12: 0 };
            }

            if (hour < 11) {
                groupedData[userId._id][day].before11 += 1;
            } else if (hour >= 11 && hour < 12) {
                groupedData[userId._id][day].between11and12 += 1;
            } else {
                groupedData[userId._id][day].after12 += 1;
            }
        });

        return Object.keys(groupedData).map((userId) => {
            const conduc = data.find((item) => item.needSubmitValidationUserId._id === userId)?.needSubmitValidationUserId;
            const dates = Object.keys(groupedData[userId]);

            return dates.map((date) => ({
                date,
                userId: conduc,
                before11: groupedData[userId][date].before11,
                between11and12: groupedData[userId][date].between11and12,
                after12: groupedData[userId][date].after12,
            }));
        });
    };

    const chartData = useMemo(() => {
        return processDataForChart(dataReportingNeeds || []);
    }, [dataReportingNeeds]);

    return (
        <div className="table-container bg-white ">
            <div className="fx-r bg-black pad-15px"></div>
            <div className="loading-bar">{isReportingNeedsLoading && <LinearProgress color="inherit" />}</div>
            <div className="dashboard">
                {chartData.map((dataForConducId, index) => (
                    <ResponsiveCard
                        title={
                            capitalizeFirstLetter(t('needs')) +
                            ' ' +
                            (dataForConducId[0].userId?.lastName.toUpperCase() || "") +
                            ' ' +
                            capitalizeFirstLetter(dataForConducId[0].userId?.firstName)
                        }
                        heightSize="small"
                        widthSize="xxlarge"
                        key={index}
                    >
                        <ResponsiveContainer>
                            <BarChart data={dataForConducId}>
                                <XAxis dataKey={'date'} name={'Date'} tickLine={false} height={40} />
                                <YAxis dataKey={'before11'} domain={[0, 'auto']} axisLine={false} tickLine={false} />
                                <Tooltip
                                    labelFormatter={(label, payload) => {
                                        const dataPoint = payload?.[0]?.payload;
                                        return dataPoint?.date || label;
                                    }}
                                />
                                <Legend verticalAlign="top" />
                                <CartesianGrid vertical={false} />
                                <Bar
                                    dataKey="before11"
                                    fill="#55AA55"
                                    stackId={'a'}
                                    name={capitalizeFirstLetter(t('before11am'))}
                                    activeBar={<Rectangle fill="green" stroke="#55AA55" />}
                                />
                                <Bar
                                    dataKey="between11and12"
                                    fill="orange"
                                    stackId={'a'}
                                    name={capitalizeFirstLetter(t('between11amAnd12pm'))}
                                    activeBar={<Rectangle fill="orange" stroke="orange" />}
                                />
                                <Bar
                                    dataKey="after12"
                                    fill="red"
                                    stackId={'a'}
                                    name={capitalizeFirstLetter(t('after12pm'))}
                                    activeBar={<Rectangle fill="red" stroke="red" />}
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    </ResponsiveCard>
                ))}
            </div>
        </div>
    );
};

export default ReportingNeeds;
