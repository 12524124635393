import React, { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from '../../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { useReportingReportsByUserQuery } from '../../../hooks/queries/useReportingQuery';
import { LinearProgress } from '@mui/material';
import {
    BarChart,
    Bar,
    Rectangle,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LineChart,
    Line,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    Radar,
    PieChart,
    Pie,
    Cell,
} from 'recharts';
import { getDaysDifference } from '../../../utils/dates';
import DropDown from '../../DropDown';
import { useAuth } from '../../../hooks/useAuth';
import { getItemFromLocalStorage, storeItemToLocalStorage } from '../../../utils/localStorage';
import { localStorageKeys } from '../../../utils/enums';
import { useActiveUsersQuery } from '../../../hooks/queries/useUsersQuery';
import { ActivityTypeData } from '../../../services/apiSLTP/planning';
import { countPropertyValues } from '../../../utils/array';
import ResponsiveCard from '../../ResponsiveCard';

interface ReportingReportsByUserProps {
    startDate: Date;
    endDate: Date;
}

const COLORS = ['#FF8042', '#00C49F', '#FFBB28', 'blue'];

const ReportingReportsByUser: React.FC<ReportingReportsByUserProps> = ({ startDate, endDate }) => {
    const [selectedUser, setSelectedUser] = useState({ label: '', value: '' });

    const { t } = useTranslation();
    const { data: dataReportingReportsByUser, isLoading: isReportingReportsByUserLoading } = useReportingReportsByUserQuery({
        params: { startDate: startDate.toISOString(), endDate: endDate.toISOString(), userId: selectedUser.value },
    });
    const { data: dataUsers, isLoading: isUsersLoading } = useActiveUsersQuery({});
    const { user } = useAuth();

    const handleUserChange = ({ label, value }: { label: string; value: string }) => {
        setSelectedUser({ label, value });
        storeItemToLocalStorage(localStorageKeys.REPORTINGREPORTSUSERID, value);
    };

    const transformActivityTypesForPieChart = (activitiesTypes: ActivityTypeData[]) => {
        const frequencyMap: Record<string, number> = {};

        activitiesTypes.forEach((activity) => {
            if (frequencyMap[activity.designation]) {
                frequencyMap[activity.designation]++;
            } else {
                frequencyMap[activity.designation] = 1;
            }
        });

        return Object.keys(frequencyMap).map((key) => ({
            name: key,
            value: frequencyMap[key],
        }));
    };

    useEffect(() => {
        const userId = getItemFromLocalStorage(localStorageKeys.REPORTINGREPORTSUSERID);
        const storedUser = dataUsers?.find((user) => user._id === userId);
        setSelectedUser({
            value: storedUser?._id || '',
            label: storedUser ? storedUser?.lastName.toUpperCase() + ' ' + capitalizeFirstLetter(storedUser?.firstName) : '',
        });
    }, [dataReportingReportsByUser, dataUsers]);

    return (
        <div className="table-container bg-white ">
            <div className="fx-r bg-black pad-15px ">
                <DropDown
                    selectedItem={selectedUser}
                    items={(dataUsers || [])
                        .sort((a, b) => {
                            const userA = a.lastName + a.firstName;
                            const userB = b.lastName + a.firstName;
                            if (userA < userB) return -1;
                            if (userA > userB) return 1;
                            return 0;
                        })
                        .map((user) => ({
                            label: user?.lastName.toUpperCase() + ' ' + capitalizeFirstLetter(user.firstName),
                            value: user?._id,
                        }))}
                    inputLabel=""
                    handleChange={(event, value) => handleUserChange({ value: value?.value || '', label: value?.label || '' })}
                    selectClassName="h-25px "
                    formClassName="pad-0-imp "
                    loading={isUsersLoading}
                    sortItems={false}
                />
            </div>

            <div className="loading-bar">{isReportingReportsByUserLoading && <LinearProgress color="inherit" />}</div>
            <div className="dashboard-container">
                {dataReportingReportsByUser && (
                    <div className="dashboard">
                        {/* Graphiques temporels */}
                        <ResponsiveCard title="Consommation" heightSize="small" widthSize="large">
                            <ResponsiveContainer>
                                <LineChart
                                    data={dataReportingReportsByUser.map((report) => ({
                                        ...report,
                                        date: new Date(report.date)
                                            .toLocaleDateString(t('localeDate'), {
                                                month: 'numeric',
                                                day: 'numeric',
                                            })
                                            .replace(/\b\w/g, (char) => char.toUpperCase()),
                                    }))}
                                >
                                    <XAxis dataKey="date" />
                                    <YAxis dataKey={'km'} type="number" yAxisId="1" />
                                    <YAxis dataKey={'fuel'} orientation="right" type="number" yAxisId="2" />
                                    <Line type="monotone" dataKey="fuel" stroke="#8884d8" yAxisId="2" />
                                    <Line type="monotone" dataKey="km" stroke="#82ca9d" yAxisId="1" />
                                    <Line type="monotone" dataKey="adBlue" stroke="#ffc658" yAxisId="2" />
                                    <Tooltip />
                                    <Legend />
                                </LineChart>
                            </ResponsiveContainer>
                        </ResponsiveCard>

                        {/* Graphique de distribution des activités */}
                        <ResponsiveCard title="Activités" widthSize="large">
                            <ResponsiveContainer>
                                <BarChart
                                    data={dataReportingReportsByUser.map((report) => ({
                                        ...report,
                                        date: new Date(report.date)
                                            .toLocaleDateString(t('localeDate'), {
                                                month: 'numeric',
                                                day: 'numeric',
                                            })
                                            .replace(/\b\w/g, (char) => char.toUpperCase()),
                                    }))}
                                >
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Bar dataKey="nbActivities" fill="#8884d8" stackId={'b'} />
                                    <Bar dataKey="nbActivitiesSent" fill="#82ca9d" stackId={'b'} />
                                    <Tooltip />
                                    <Legend />
                                </BarChart>
                            </ResponsiveContainer>
                        </ResponsiveCard>
                        <ResponsiveCard title="Tours de camions">
                            {/* Graphique radar pour les types d'activités */}
                            <ResponsiveContainer>
                                <RadarChart
                                    data={dataReportingReportsByUser.map((report) => ({
                                        ...report,
                                        date: new Date(report.date)
                                            .toLocaleDateString(t('localeDate'), {
                                                month: 'numeric',
                                                day: 'numeric',
                                            })
                                            .replace(/\b\w/g, (char) => char.toUpperCase()),
                                    }))}
                                >
                                    <PolarGrid />
                                    <PolarAngleAxis dataKey="date" />
                                    <PolarRadiusAxis />
                                    <Radar
                                        name={capitalizeFirstLetter(t('trucksRides'))}
                                        dataKey="nbTruckRides"
                                        stroke="#8884d8"
                                        fill="#8884d8"
                                        fillOpacity={0.6}
                                    />
                                    <Tooltip />
                                    <Legend />
                                </RadarChart>
                            </ResponsiveContainer>
                        </ResponsiveCard>
                        <ResponsiveCard title="Types d'activités">
                            {/* Graphique à secteurs pour les sites */}
                            <ResponsiveContainer>
                                <PieChart>
                                    <Pie
                                        data={countPropertyValues(
                                            dataReportingReportsByUser?.flatMap((report) => report.activitiesTypes),
                                            'designation'
                                        )}
                                        dataKey="number"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={80}
                                        paddingAngle={1}
                                    >
                                        {countPropertyValues(
                                            dataReportingReportsByUser?.flatMap((report) => report.activitiesTypes),
                                            'designation'
                                        ).map((entry, index) => {
                                            console.log(COLORS[index % COLORS.length]);
                                            return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />;
                                        })}
                                    </Pie>

                                    <Tooltip />
                                    <Legend />
                                    <text
                                        x="50%"
                                        y="45%"
                                        textAnchor="middle"
                                        dominantBaseline="middle"
                                        style={{
                                            fontSize: '18px',
                                            fontWeight: 'bold',
                                            fill: '#333',
                                        }}
                                    >
                                        {countPropertyValues(
                                            dataReportingReportsByUser?.flatMap((report) => report.activitiesTypes),
                                            'designation'
                                        ).reduce((acc, item) => acc + item.number, 0)}
                                    </text>
                                </PieChart>
                            </ResponsiveContainer>
                        </ResponsiveCard>
                        <ResponsiveCard title="Tours de camions" widthSize="large">
                            {/* Graphique des trajets de camion */}
                            <ResponsiveContainer>
                                <BarChart
                                    data={dataReportingReportsByUser.map((report) => ({
                                        ...report,
                                        date: new Date(report.date)
                                            .toLocaleDateString(t('localeDate'), {
                                                month: 'numeric',
                                                day: 'numeric',
                                            })
                                            .replace(/\b\w/g, (char) => char.toUpperCase()),
                                    }))}
                                >
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Bar dataKey="nbTruckRides" name={'nombre de tours'} fill="#ffc658" />
                                    <Tooltip />
                                    <Legend />
                                </BarChart>
                            </ResponsiveContainer>
                        </ResponsiveCard>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReportingReportsByUser;
