import axios, { AxiosResponse } from 'axios';
import { apiClient } from '../endpoints';

interface User {
    _id: string;
    codeProC: string;
    lastName: string;
    firstName: string;
    jobId: string;
    username: string;
    profileId: string;
    latitude: number;
    longitude: number;
    color: string;
    phoneNumber?: string;
    isSST?: boolean;
}

interface MachineType {
    designation: string;
    _id: string;
}

export interface Machine {
    crudStatus: number;
    _id: string;
    codeProC: string;
    designation: string;
    machineTypeId: MachineType;
    userId?: User;
}

interface MachinesResponse {
    message: string;
    data: Machine[];
}

export const getAllMachines = async (): Promise<MachinesResponse> => {
    try {
        const response: AxiosResponse<MachinesResponse> = await apiClient.get('/machines');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export const getAllActiveMachines = async (): Promise<MachinesResponse> => {
    try {
        const response: AxiosResponse<MachinesResponse> = await apiClient.get('/machines/active');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface UpdateMachineBody {
    designation?: string;
    codeProC?: string;
    machineTypeId?: string;
    userId?: string;
    crudStatus?: number;
}

export interface MachineResponse {
    message: string;
    data: Machine;
}

export const updateMachine = async (machineId: string, data: UpdateMachineBody): Promise<MachineResponse> => {
    try {
        const response: AxiosResponse<MachineResponse> = await apiClient.patch('/machines/' + machineId, data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface CreateMachineBody {
    designation: string;
    codeProC: string;
    machineTypeId: string;
    userId: string;
    crudStatus?: number;
}

export const createMachine = async (data: CreateMachineBody): Promise<MachineResponse> => {
    try {
        const response: AxiosResponse<MachineResponse> = await apiClient.post('/machines', data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface MachineWithLastLocation {
    _id: string;
    latestState: number;
    latestDate: string;
    designation: string;
    codeProC: string;
    siteName: string;
    siteId: string;
}

export interface MachinesWithLastLocationsResponse {
    message: string;
    data: MachineWithLastLocation[];
}

export const getMachineWithLastLocation = async (date: Date): Promise<MachinesWithLastLocationsResponse> => {
    try {
        const response: AxiosResponse<MachinesWithLastLocationsResponse> = await apiClient.get('/daily-machines/locations/date/' + date.toISOString() );
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};