import { useMutation, MutationFunction, MutationOptions } from 'react-query';
import { ActivitiesResponse, ActivityResponse } from '../../services/apiSLTP/planning';
import { sendPlanning, sendPlanningForActivity, importNeeds, getBackPlanning, getBackPlanningForActivity } from '../../services/apiSLTP/planning';

type UseSendPlanningMutationOptions = MutationOptions<ActivitiesResponse, Error, { date: string, withNotification?: boolean }>;
type UseSendPlanningForActivityMutationOptions = MutationOptions<ActivityResponse, Error, { activityId: string, withNotification?: boolean }>;
type UseImportNeedsMutationOptions = MutationOptions<ActivitiesResponse, Error, { date: string }>;
type UseGetBackPlanningMutationOptions = MutationOptions<ActivitiesResponse, Error, { date: string, withNotification?: boolean }>;
type UseGetBackPlanningForActivityMutationOptions = MutationOptions<ActivityResponse, Error, { activityId: string, withNotification?: boolean }>;

export const useSendPlanningMutation = (options?: UseSendPlanningMutationOptions) => {
    const sendPlanningMutation: MutationFunction<ActivitiesResponse, { date: string, withNotification?: boolean }> = async (data) => {
        try {
            const response: ActivitiesResponse = await sendPlanning(data.date, { withNotification: data.withNotification });
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during sendPlanning');
        }
    };

    return useMutation(sendPlanningMutation, options);
};

export const useSendPlanningForActivityMutation = (options?: UseSendPlanningForActivityMutationOptions) => {
    const sendPlanningForActivityMutation: MutationFunction<ActivityResponse, { activityId: string, withNotification?: boolean }> = async (data) => {
        try {
            const response: ActivityResponse = await sendPlanningForActivity(data.activityId, { withNotification: data.withNotification });
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during sendPlanningForActivity');
        }
    };

    return useMutation(sendPlanningForActivityMutation, options);
};

export const useImportNeedsMutation = (options?: UseImportNeedsMutationOptions) => {
    const importNeedsMutation: MutationFunction<ActivitiesResponse, { date: string }> = async (data) => {
        try {
            const response: ActivitiesResponse = await importNeeds(data.date);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during importNeeds');
        }
    };

    return useMutation(importNeedsMutation, options);
};

export const useGetBackPlanningMutation = (options?: UseGetBackPlanningMutationOptions) => {
    const getBackPlanningMutation: MutationFunction<ActivitiesResponse, { date: string, withNotification?: boolean }> = async (data) => {
        try {
            const response: ActivitiesResponse = await getBackPlanning(data.date, { withNotification: data.withNotification });
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during getBackPlanning');
        }
    };

    return useMutation(getBackPlanningMutation, options);
};

export const useGetBackPlanningForActivityMutation = (options?: UseGetBackPlanningForActivityMutationOptions) => {
    const getBackPlanningForActivityMutation: MutationFunction<ActivityResponse, { activityId: string, withNotification?: boolean }> = async (data) => {
        try {
            const response: ActivityResponse = await getBackPlanningForActivity(data.activityId, { withNotification: data.withNotification });
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during getBackPlanningForActivity');
        }
    };

    return useMutation(getBackPlanningForActivityMutation, options);
};
