import React from 'react';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../hooks/useAuth';
import { logout } from '../services/apiSLTP/auth';
import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter } from '../utils/capitalize';
import Icon from '../components/Icon';

const ProfilePage: React.FC = () => {
    const { t } = useTranslation();
    const { user, logout: removeConnection } = useAuth();
    const navigate = useNavigate();

    const handleLogout = async () => {
        await logout();
        removeConnection();
        navigate('/login');
    };

    return (
        <div>
            <Header title={t('profile')} isSearchable={false} isDateVisible={false} >
                <Icon name='logout' title={capitalizeFirstLetter(t('logout'))} onClick={() => handleLogout()} color='crimson' dark />
            </Header>
            <div className="pad-l-15px pad-r-15px">
                <dl className='bg-white border-radius-5px pad-5px'>
                    <dt className="font-w-600">{capitalizeFirstLetter(t('firstName'))}</dt>
                    <dd>{capitalizeFirstLetter(user?.firstName)}</dd>
                    <dt className="font-w-600">{capitalizeFirstLetter(t('lastName'))}</dt>
                    <dd>{user?.lastName.toUpperCase()}</dd>
                    <dt className="font-w-600">{capitalizeFirstLetter(t('id'))}</dt>
                    <dd>{user?._id}</dd>
                    <dt className="font-w-600">{capitalizeFirstLetter(t('userlogin'))}</dt>
                    <dd>{user?.username}</dd>
                    <dt className="font-w-600">{capitalizeFirstLetter(t('codeProC'))}</dt>
                    <dd>{user?.codeProC}</dd>
                    <dt className="font-w-600">{capitalizeFirstLetter(t('profile'))}</dt>
                    <dd>{capitalizeFirstLetter(user?.profileId.type)}</dd>
                    <dt className="font-w-600">{capitalizeFirstLetter(t('address'))}</dt>
                    <dd>{user?.address}</dd>
                    <dt className="font-w-600">{capitalizeFirstLetter(t('job'))}</dt>
                    <dd>{capitalizeFirstLetter(user?.jobId.designation)}</dd>
                </dl>
            </div>
        </div>
    );
};

export default ProfilePage;
