import React, { useRef } from 'react';
import { useDrag, useDrop, XYCoord } from 'react-dnd';
import Icon from './Icon';

interface DroppableSimpleCellProps {
    dropId: { lineId: string; index: number };
    moveCell: (fromLineId: string, toLineId: string) => Promise<void>;
    children?: React.ReactNode;
    itemType: string;
    disabled?: boolean;
    index: number;
    onDragStart?: () => void;
    showDelete?: boolean;
    onDelete?: () => void;
    state?: number;
    onDuplicate?: () => void;
    onDoubleClick?: () => void;
    warning?: boolean;
}

const DroppableSimpleCell: React.FC<DroppableSimpleCellProps> = ({
    dropId,
    moveCell,
    children,
    itemType,
    disabled = false,
    index,
    onDelete = () => {},
    onDoubleClick = () => {},
    onDragStart = () => {},
    onDuplicate = () => {},
    showDelete,
    state,
    warning,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const lastMoved = useRef<{ fromLineId: string; fromIndex: number; toLineId: string; toIndex: number } | null>(null);

    const [{ isOver, canDrop, handlerId }, drop] = useDrop({
        accept: itemType,
        drop: (item: { lineId: string; index: number }) => {},
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            handlerId: monitor.getHandlerId(),
        }),
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = dropId.index;

            // Don't replace items with themselves
            if (item.lineId === dropId.lineId) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            if (
                lastMoved.current?.fromLineId === item.lineId &&
                lastMoved.current?.toLineId === dropId.lineId &&
                lastMoved.current?.fromIndex === item.index &&
                lastMoved.current?.toIndex === dropId.index
            ) {
                return;
            }

            // Met à jour le dernier mouvement
            lastMoved.current = { fromLineId: item.lineId, fromIndex: item.index, toLineId: dropId.lineId, toIndex: dropId.index };

            // Time to actually perform the action
            //console.log('moove', item.lineId, ' / ', dropId.lineId);
            moveCell(item.lineId, dropId.lineId);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            const tmp = item;
            item = dropId;
            //item = dropId;
        },
    });

    const [{ isDragging }, drag] = useDrag(
        {
            type: itemType,
            item: () => ({ lineId: dropId.lineId, index: dropId.index }),
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        },
        [dropId]
    );

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (disabled) return;
        if (event.ctrlKey && event.key === 'b') {
            onDuplicate();
        }
    };

    const opacity = isDragging ? 0 : 1;

    if (!disabled) {
        drag(drop(ref));
    }

    return (
        <div
            ref={ref}
            data-handler-id={handlerId}
            tabIndex={0}
            onKeyDown={handleKeyDown}
            aria-disabled={disabled}
            className={`droppable fx-r fx-justify-sb bg-lightgray border-radius-5px w-100 min-h-25px m-l-5 ${warning && 'outline-1px'} ${
                !disabled && 'hover-black focus-outline'
            }`}
            style={{
                backgroundColor: isOver ? 'gray' : '#D3D3D3',
                cursor: disabled ? 'not-allowed' : 'default',
                transform: 'translate3d(0, 0, 0)',
                outlineColor: warning ? 'darkorange' : undefined,
                opacity,
                margin: 5,
            }}
            onDragStart={
                disabled
                    ? undefined
                    : () => {
                          onDragStart();
                      }
            }
        >
            {warning && (
                <div
                    style={{
                        position: 'absolute',
                        width: 13,
                        height: 13,
                        top: -5,
                        left: -5,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 10,
                        color: 'black',
                        fontSize: 10,
                        backgroundColor: 'darkorange',
                    }}
                >
                    !
                </div>
            )}
            <div className="draggable-children" onDoubleClick={disabled ? undefined : onDoubleClick}>
                {children}
            </div>
            <div className="min-w-10px">{showDelete && !disabled && <Icon name="close" onClick={onDelete} disabled={disabled} />}</div>
        </div>
    );
};

export default DroppableSimpleCell;
