import { useQuery, QueryFunction } from 'react-query';
import { getAllTrucks, getTrucksByCompanyId, Truck } from '../../services/apiSLTP/materials/trucks';

type UseTrucksQueryOptions = {};

const getTrucks: QueryFunction<Truck[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseTrucksQueryOptions];
    const {} = options;

    const response = await getAllTrucks();
    return response.data;
};

export const useTrucksQuery = (options: UseTrucksQueryOptions) => {
    return useQuery(['trucks', options], getTrucks);
};

type UseTruckByCompanyQueryOptions = { truckCompanyId: string };

const getTrucksByCompany: QueryFunction<Truck[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseTruckByCompanyQueryOptions];
    const { truckCompanyId } = options;

    const response = await getTrucksByCompanyId(truckCompanyId);
    return response.data;
};

export const useTruckByCompanyQuery = (options: UseTruckByCompanyQueryOptions) => {
    return useQuery(['trucks by company', options], getTrucksByCompany);
};
