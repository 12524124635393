import axios, { AxiosResponse } from 'axios';
import { apiClient } from './endpoints';
import { DailyMachine, DailyRental, DailySubContractor, DailyTempWorker, DailyTool } from './planning';
import { reportState } from '../../utils/enums';
import { User } from './users';
import { DailyTruckPlanned } from './dailyReports/dailyTrucksPlanned';

export interface DropDownItem {
    label: string;
    value: string;
}

interface Job {
    _id: string;
    designation: string;
    order: number;
    crudStatus: number;
}

export interface Report {
    _id: string;
    userId: User;
    date: string;
    reportState: number;
    __v: number;
    fuel?: number;
    km?: number;
    gnr?: number;
    reportSubmitValidationTimeStamp?: string;
}

interface Site {
    _id: string;
    codeProC: string;
    name: string;
    stateProC: number;
    conducId: User;
    address: string;
    crudStatus: number;
    entityId: string;
}

interface ActivityType {
    _id: string;
    designation: string;
}

export interface DataItem {
    _id: string;
    activityTypeId: ActivityType;
    reportId: Report;
    activityState: number;
    siteId: Site;
    startTime: string;
    subContractorsState: number;
    tempWorkersState: number;
    validationTimeState: number;
    validationTime?: string;
    validationTimestamp?: string;
    observationsPlanning: string;
    observationsWorker: string;
    observationsPlanningState: number;
    observationsWorkerState: number;
    needsValidationTime: string;
    planningValidationTime: string;
    isTruckManager: boolean;
    dailyMachines: DailyMachine[];
    dailyTools: DailyTool[];
    dailyRentals: DailyRental[];
    dailySubContractors: DailySubContractor[];
    dailyTempWorkers: DailyTempWorker[];
    dailyTrucksPlanned: DailyTruckPlanned[];
    needSubmitValidationTimeStamp?: string;
    planningSubmitValidationTimeStamp?: string;
    stepMode: number;
    isSpecialHidden?: boolean
}

export interface ActivitiesResponse {
    message: string;
    data: DataItem[];
}

export const getActivitiesByDateAndState = async (
    date: string,
    minState: number,
    maxState: number,
    stepMode: number
): Promise<ActivitiesResponse> => {
    try {
        const response: AxiosResponse<ActivitiesResponse> = await apiClient.get(
            'activities/date/' + date + '/state/' + minState + '/and/' + maxState + '/stepMode/' + stepMode
        );
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export const getActivitiesBetweenDateAndState = async (
    startDate: string,
    endDate: string,
    minState: number,
    maxState: number,
    stepMode: number,
    userId: string
): Promise<ActivitiesResponse> => {
    try {
        const response: AxiosResponse<ActivitiesResponse> = await apiClient.get(
            'activities/startDate/' +
                startDate +
                '/endDate/' +
                endDate +
                '/state/' +
                minState +
                '/and/' +
                maxState +
                '/stepMode/' +
                stepMode +
                '/user/' +
                userId
        );
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface DailyMachineResponse {
    message: string;
    data: DailyMachine;
}

export const updateDailyMachine = async (
    id: string,
    body: { activityId?: string; machineId?: string; state?: number; createTransfer?: boolean }
): Promise<DailyMachineResponse> => {
    try {
        const response: AxiosResponse<DailyMachineResponse> = await apiClient.patch('/daily-machines/' + id, body);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface DailyToolResponse {
    message: string;
    data: DailyTool;
}

export const updateDailyTool = async (
    id: string,
    body: { activityId?: string; toolId?: string; state?: number; createTransfer?: boolean }
): Promise<DailyToolResponse> => {
    try {
        const response: AxiosResponse<DailyToolResponse> = await apiClient.patch('/daily-tools/' + id, body);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface DailyRentalResponse {
    message: string;
    data: DailyRental;
}

export const updateDailyRental = async (
    id: string,
    body: { activityId?: string; rentalTypeId?: string; state?: number; designation?: string; createTransfer?: boolean }
): Promise<DailyRentalResponse> => {
    try {
        const response: AxiosResponse<DailyRentalResponse> = await apiClient.patch('/daily-rentals/' + id, body);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface DailySubContractorResponse {
    message: string;
    data: DailySubContractor;
}

export const updateDailySubContractor = async (
    id: string,
    body: { activityId?: string; subContractorTypeId?: string; durationDay?: number; state?: number }
): Promise<DailySubContractorResponse> => {
    try {
        const response: AxiosResponse<DailySubContractorResponse> = await apiClient.patch('/daily-subcontractors/' + id, body);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

interface DailyTempWorkerResponse {
    message: string;
    data: DailyTempWorker;
}

export const updateDailyTempWorker = async (
    id: string,
    body: { activityId?: string; tempWorkerTypeId?: string; durationDay?: number; state?: number; durationHours?: number; tempWorkerName?: string }
): Promise<DailyTempWorkerResponse> => {
    try {
        const response: AxiosResponse<DailyTempWorkerResponse> = await apiClient.patch('/daily-tempworkers/' + id, body);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export const importActivitiesByConduc = async (conducId: string, date: string, fromState: number, toState: number): Promise<ActivitiesResponse> => {
    try {
        const response: AxiosResponse<ActivitiesResponse> = await apiClient.patch('/activities/conduc/' + conducId + '/date/' + date + '/state', {
            fromState,
            toState,
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export const importActivitiesBySite = async (
    siteId: string,
    date: string,
    fromState: number,
    toState: number,
    stepMode: number
): Promise<ActivitiesResponse> => {
    try {
        const response: AxiosResponse<ActivitiesResponse> = await apiClient.patch(
            '/activities/site/' + siteId + '/date/' + date + '/stepMode/' + stepMode + '/state',
            {
                fromState,
                toState,
            }
        );
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export const importActivitiesByDate = async (date: string, fromState: number, toState: number): Promise<ActivitiesResponse> => {
    try {
        const response: AxiosResponse<ActivitiesResponse> = await apiClient.patch('/activities/date/' + date + '/state', {
            fromState,
            toState,
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export const duplicateActivities = async (
    date: string,
    state: number,
    stepMode: number,
    newStepMode: number,
    newState: number
): Promise<ActivitiesResponse> => {
    try {
        const response: AxiosResponse<ActivitiesResponse> = await apiClient.post(
            '/activities/duplicate/date/' + date + '/stepMode/' + stepMode + '/state/' + state,
            {
                newStepMode,
                newState,
            }
        );
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export const duplicateActivity = async (activityId: string, newStepMode: number, newState: number): Promise<ActivitiesResponse> => {
    try {
        const response: AxiosResponse<ActivitiesResponse> = await apiClient.post('/activities/duplicate/' + activityId, {
            newStepMode,
            newState,
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export const cancelActivities = async (
    date: string,
    state: number,
    stepMode: number,
    newStepMode: number,
    newState: number
): Promise<ActivitiesResponse> => {
    try {
        const response: AxiosResponse<ActivitiesResponse> = await apiClient.patch(
            '/activities/cancel/date/' + date + '/stepMode/' + stepMode + '/state/' + state,
            {
                newStepMode,
                newState,
            }
        );
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export const cancelActivity = async (activityId: string, state: number, stepMode: number): Promise<ActivitiesResponse> => {
    try {
        const response: AxiosResponse<ActivitiesResponse> = await apiClient.patch(
            '/activities/cancel/' + activityId + '/stepMode/' + stepMode + '/state/' + state
        );
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
