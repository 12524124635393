import axios, { AxiosResponse } from 'axios';
import { apiClient } from '../endpoints';
import { User } from '../users';
import { TruckCompany } from './trucksCompanies';
import { TruckType } from './trucksTypes';

export interface Truck {
    crudStatus: number;
    _id: string;
    codeProC: string;
    designation: string;
    plateNumber: string;
    truckTypeId: TruckType;
    truckCompanyId: TruckCompany;
    userId: User;
    isIncomplete?: boolean;
}

export interface TrucksResponse {
    message: string;
    data: Truck[];
}

export interface TruckResponse {
    message: string;
    data: Truck;
}

export const getAllTrucks = async (): Promise<TrucksResponse> => {
    try {
        const response: AxiosResponse<TrucksResponse> = await apiClient.get('/trucks');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export const getAllActiveTrucks = async (): Promise<TrucksResponse> => {
    try {
        const response: AxiosResponse<TrucksResponse> = await apiClient.get('/trucks/active');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export const getTrucksByCompanyId = async (truckCompanyId: string): Promise<TrucksResponse> => {
    try {
        const response: AxiosResponse<TrucksResponse> = await apiClient.get('/trucks/company/' + truckCompanyId);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface UpdateTruckBody {
    designation?: string;
    codeProC?: string;
    truckTypeId?: string;
    userId?: string;
    crudStatus?: number;
    plateNumber?: string;
    truckCompanyId?: string;
}

export const updateTruck = async (truckId: string, data: UpdateTruckBody): Promise<TruckResponse> => {
    try {
        const response: AxiosResponse<TruckResponse> = await apiClient.patch('/trucks/' + truckId, data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface CreateTruckBody {
    designation: string;
    codeProC: string;
    truckTypeId: string;
    plateNumber: string;
    userId?: string;
    truckCompanyId: string;
    crudStatus?: number;
}

export const createTruck = async (data: CreateTruckBody): Promise<TruckResponse> => {
    try {
        const response: AxiosResponse<TruckResponse> = await apiClient.post('/trucks', data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
