import axios, { AxiosResponse } from 'axios';
import { apiClient } from './endpoints';

interface Job {
    _id: string;
    designation: string;
    order: number;
    crudStatus: number;
}

interface Profile {
    _id: string;
    permsLevel: number;
    type: string;
}

export interface User {
    _id: string;
    codeProC: string;
    lastName: string;
    firstName: string;
    jobId: Job;
    username: string;
    profileId: Profile;
    latitude: number;
    longitude: number;
    color: string;
    crudStatus: number;
    address: string;
    isSpecial?: boolean;
    phoneNumber?: string;
    isSST?: boolean;
}

export interface UsersResponse {
    data: User[];
    message: string;
}

export const getAllUsers = async (): Promise<UsersResponse> => {
    try {
        const response: AxiosResponse<UsersResponse> = await apiClient.get('/users');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export const getAllActiveUsers = async (): Promise<UsersResponse> => {
    try {
        const response: AxiosResponse<UsersResponse> = await apiClient.get('/users/active');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface UpdateUserBody {
    lastName?: string;
    firstName?: string;
    username?: string;
    address?: string;
    codeProC?: string;
    jobId?: string;
    profileId?: string;
    color?: string;
    crudStatus?: number;
    isSpecial?: boolean;
    phoneNumber?: string;
    isSST?: boolean;
}

export interface UserResponse {
    data: User;
    message: string;
}

export const updateUser = async (userId: string, data: UpdateUserBody): Promise<UserResponse> => {
    try {
        const response: AxiosResponse<UserResponse> = await apiClient.patch('/users/' + userId, data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface CreateUserBody {
    lastName: string;
    firstName: string;
    username: string;
    address: string;
    codeProC: string;
    jobId: string;
    profileId: string;
    color: string;
    password: string;
    isSpecial?: boolean;
    crudStatus?: number;
    phoneNumber?: string;
    isSST?: boolean;
}

export const createUser = async (data: CreateUserBody): Promise<UserResponse> => {
    try {
        const response: AxiosResponse<UserResponse> = await apiClient.post('/users', data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
