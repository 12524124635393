import { useQuery, QueryFunction } from 'react-query';
import { getReportingsReports, getReportingsReportsByUser, ReportingQueryParams, ReportingReports, ReportingReportsByUser, ReportingReportsByUserParams, getNeedsValidationTime, NeedsValidationTimeParams, NeedsValidationTimeData } from '../../services/apiSLTP/reporting/reporting';

type UseReportingReportsQueryOptions = {
    params: ReportingQueryParams;
};

const getReportingReports: QueryFunction<ReportingReports[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseReportingReportsQueryOptions];
    const { params } = options;

    const response = await getReportingsReports(params);
    return response.data;
};

export const useReportingReportsQuery = (options: UseReportingReportsQueryOptions) => {
    return useQuery(['reportingReports', options], getReportingReports);
};

type UseReportingReportsByUserQueryOptions = {
    params: ReportingReportsByUserParams;
};

const getReportingReportsByUser: QueryFunction<ReportingReportsByUser[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseReportingReportsByUserQueryOptions];
    const { params } = options;

    const response = await getReportingsReportsByUser(params);
    return response.data;
};

export const useReportingReportsByUserQuery = (options: UseReportingReportsByUserQueryOptions) => {
    return useQuery(['reportingReportsByUser', options], getReportingReportsByUser, {enabled: !!options.params.userId.length});
};

type UseNeedsValidationTimeQueryOptions = {
    params: NeedsValidationTimeParams;
};

const getNeedsValidationTimeQuery: QueryFunction<NeedsValidationTimeData[]> = async ({ queryKey }) => {
    const [, options] = queryKey as [string, UseNeedsValidationTimeQueryOptions];
    const { params } = options;

    const response = await getNeedsValidationTime(params);
    return response.data;
};

export const useReportingNeedsValidationTimeQuery = (options: UseNeedsValidationTimeQueryOptions) => {
    return useQuery(['reportingNeedsValidationTime', options], getNeedsValidationTimeQuery);
};
