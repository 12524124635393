import React, { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from '../../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { DailyTruckRide } from '../../../services/apiSLTP/dailyReports/dailyTrucksRides';
import { DataItem, Report } from '../../../services/apiSLTP/activities';
import {
    getMaterialStateInfos,
    getStateInfos,
    getTruckModeInfos,
    localStorageKeys,
    materialState,
    permissionLevels,
    reportState,
    truckMode,
    truckRideActivityTypeId,
} from '../../../utils/enums';
import { getMaterialStateColor, getStateColor } from '../../../utils/colors';
import { countPropertyValues, removeDuplicates } from '../../../utils/array';
import Icon from '../../Icon';
import DriversAmplirollRow from './DriversAmplirollRow';
import { useAuth } from '../../../hooks/useAuth';
import ReportsRow from './ReportsRow';
import { Truck } from '../../../services/apiSLTP/materials/trucks';
import { useActiveUsersQuery } from '../../../hooks/queries/useUsersQuery';
import DropDown from '../../DropDown';
import StateCell from '../../cells/StateCell';
import ColorBar from '../../ColorBar';
import { updateActivity } from '../../../services/apiSLTP/planning';
import { updateReportState } from '../../../services/apiSLTP/dailyReports/reports';
import RentalsCell from '../../cells/RentalsCell';
import ToolsCell from '../../cells/ToolsCell';
import MachinesCell from '../../cells/MachinesCell';
import TempWorkersCell from '../../cells/TempWorkersCell';
import SubContractorsCell from '../../cells/SubContractorsCell';
import SiteCell from '../../cells/SiteCell';
import TruckIcon from '../../TruckIcon';
import CustomToggle from '../../CustomToggle';
import TrucksRidesRow from './TrucksRidesRows';
import { getItemFromLocalStorage, storeItemToLocalStorage } from '../../../utils/localStorage';
import { useActiveSitesQuery } from '../../../hooks/queries/useSitesQuery';

interface SiteTableProps {
    activities: DataItem[];
    refetch: () => {};
    trucks: Truck[];
    dailyTrucksRides: DailyTruckRide[];
    currentDate: Date;
}

const SiteTable: React.FC<SiteTableProps> = ({ activities, trucks, refetch, dailyTrucksRides, currentDate }) => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const [selectedSite, setSelectedSite] = useState({ label: '', value: '' });

    const { data: dataSites, isLoading: isSitesLoading } = useActiveSitesQuery({});

    const sortedActivitiesBySite = () => {
        return activities
            .filter((activity) => activity.siteId._id === selectedSite.value)
            .sort((a, b) => {
                return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
            });
    };

    const getReport = (userId: string) => {
        //setSelectedReport(activities.find((activity) => activity.reportId.userId._id === userId)?.reportId || null);
    };

    const handleSiteChange = ({ label, value }: { label: string; value: string }) => {
        setSelectedSite({ label, value });
        storeItemToLocalStorage(localStorageKeys.SUPERVISIONREPORTSITEID, value);
    };

    const handleActivityUnlock = async (activityId: string, reportId: string) => {
        await updateActivity(activityId, { activityState: reportState.REPORT });
        await updateReportState(reportId, reportState.REPORT);
        refetch();
    };

    const sortedDailyTrucksRides = () => {
        return dailyTrucksRides.filter((value) => {
            return sortedActivitiesBySite()
                .map((activity) => activity._id)
                .includes(value.activityId);
        });
    };

    const reduceDailyTrucksRides = () => {
        return removeDuplicates(sortedDailyTrucksRides(), ['activityId']).sort((a, b) => {
            const activityA = activities.find((value) => value._id === a?.activityId);
            const activityB = activities.find((value) => value._id === b?.activityId);

            if (activityA?.isTruckManager && !activityB?.isTruckManager) return -1;
            if (!activityA?.isTruckManager && activityB?.isTruckManager) return 1;

            const dateA = activityA?.startTime || '';
            const dateB = activityB?.startTime || '';

            if (dateA < dateB) return -1;
            if (dateA > dateB) return 1;

            return 0;
        });
    };

    const formatTime = (time: string | undefined) => {
        if (time === undefined) return '';
        const date = new Date(time);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const calculateStartTime = () => {
        let dates: Date[] = [];
        for (const activity of sortedActivitiesBySite()) {
            if (activity.startTime) dates.push(new Date(activity.startTime));
        }
        for (const truckRide of sortedDailyTrucksRides()) {
            if (truckRide.departureTime) dates.push(new Date(truckRide.departureTime));
            if (truckRide.time) dates.push(new Date(truckRide.time));
        }
        if (!dates.length) return undefined;
        const minDate = new Date(Math.min(...dates.map((date) => date.getTime())));

        return minDate;
    };

    const calculateEndTime = () => {
        let dates: Date[] = [];
        for (const activity of sortedActivitiesBySite()) {
            if (activity.validationTime) dates.push(new Date(activity.validationTime));
        }
        for (const truckRide of sortedDailyTrucksRides()) {
            if (truckRide.arrivalTime) dates.push(new Date(truckRide.arrivalTime));
            if (truckRide.time) dates.push(new Date(truckRide.time));
            if (truckRide.secondTime) dates.push(new Date(truckRide.secondTime));
        }
        if (!dates.length) return undefined;

        const maxDate = new Date(Math.max(...dates.map((date) => date.getTime())));

        return maxDate;
    };

    useEffect(() => {
        getReport(selectedSite.value);
    }, [selectedSite]);

    useEffect(() => {
        const siteId = getItemFromLocalStorage(localStorageKeys.SUPERVISIONREPORTSITEID);
        const storedSite = dataSites?.find((site) => site._id === siteId);
        setSelectedSite({
            value: storedSite?._id || '',
            label: storedSite ? storedSite?.name + (activities.map((activity) => activity.siteId._id).includes(storedSite._id) ? '📝' : '') : '',
        });
    }, [activities, dataSites]);

    return (
        <div>
            <div className="fx-r bg-black border-radius-5px pad-15px m-b-15px">
                <DropDown
                    selectedItem={selectedSite}
                    items={(dataSites || [])
                        .sort((a, b) => {
                            if (
                                activities.map((activity) => activity.siteId._id).includes(a._id) &&
                                !activities.map((activity) => activity.siteId._id).includes(b._id)
                            )
                                return -1;
                            if (
                                !activities.map((activity) => activity.siteId._id).includes(a._id) &&
                                activities.map((activity) => activity.siteId._id).includes(b._id)
                            )
                                return 1;

                            const siteA = a.name;
                            const siteB = b.name;

                            if (siteA < siteB) return -1;
                            if (siteA > siteB) return 1;

                            return 0;
                        })
                        .map((site) => ({
                            label: site.name + (activities.map((activity) => activity.siteId._id).includes(site._id) ? '📝' : ''),
                            value: site?._id,
                        }))}
                    inputLabel=""
                    handleChange={(event, value) => handleSiteChange({ value: value?.value || '', label: value?.label || '' })}
                    selectClassName="h-25px "
                    formClassName="pad-0-imp "
                    loading={isSitesLoading}
                    sortItems={false}
                />
                {!!selectedSite.value.length && (
                    <>
                        {!isSitesLoading && !activities.filter((activity) => activity.siteId._id === selectedSite.value).length && (
                            <div className="pad-l-15px text-red">
                                <b>⚠️ {t('noReport').toUpperCase()}</b>
                            </div>
                        )}
                        {calculateStartTime() && (
                            <div className="text-white pad-l-15px">
                                <b>{capitalizeFirstLetter(t('start')) + ' : '}</b>
                                {formatTime(calculateStartTime()?.toISOString())}
                            </div>
                        )}
                        {calculateEndTime() && (
                            <div className="text-white pad-l-15px">
                                <b>{capitalizeFirstLetter(t('end')) + ' : '}</b>
                                {formatTime(calculateEndTime()?.toISOString())}
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className="table-container pad-l-10px pad-r-10px  border-radius-5px" style={{ maxHeight: 'calc(100vh - 240px)' }}>
                <div className="table-container " style={{ maxHeight: 'auto', marginBottom: 15 }}>
                    <table id="csv-planning-obj">
                        <thead>
                            <tr>
                                <th scope="col" className="w-200px pos-sy left-0 z-idx-4">
                                    {capitalizeFirstLetter(t('person'))}
                                </th>
                                <th scope="col" className="w-150px">
                                    <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('activityStatus'))}</div>
                                </th>
                                <th scope="col" className="w-75px">
                                    {capitalizeFirstLetter(t('start'))}
                                </th>
                                <th scope="col" className="w-75px">
                                    {capitalizeFirstLetter(t('end'))}
                                </th>
                                <th scope="col" className="w-100px">
                                    <div className="fx-r fx-justify-c txt-align-c">{capitalizeFirstLetter(t('truckManager'))}</div>
                                </th>
                                <th scope="col" className="w-300px">
                                    {capitalizeFirstLetter(t('observations'))}
                                </th>
                                <th scope="col" className="w-300px">
                                    {capitalizeFirstLetter(t('machines'))}
                                </th>
                                <th scope="col" className="w-300px">
                                    {capitalizeFirstLetter(t('tools'))}
                                </th>
                                <th scope="col" className="w-300px">
                                    {capitalizeFirstLetter(t('rentals'))}
                                </th>
                                <th scope="col" className="w-200px">
                                    {capitalizeFirstLetter(t('tempWorkers'))}
                                </th>
                                <th scope="col" className="w-200px">
                                    {capitalizeFirstLetter(t('subContractors'))}
                                </th>
                                <th scope="col" className="w-200px">
                                    {capitalizeFirstLetter(t('activity'))}
                                </th>
                                {user && user?.profileId?.permsLevel <= permissionLevels.SUPERVISION && (
                                    <th scope="col" className="w-100px">
                                        {capitalizeFirstLetter(t('actions'))}
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody id="fu-planning-obj">
                            {sortedActivitiesBySite().map((activity, index, array) => {
                                return (
                                    <tr key={activity._id}>
                                        <td className="pos-sy left-0 z-idx-2">
                                            <div className="fx-r fx-items-c">
                                                <div className="pad-r-5px">
                                                    {capitalizeFirstLetter(activity.reportId.userId.firstName)}{' '}
                                                    {activity.reportId.userId.lastName.toUpperCase()}
                                                </div>
                                                {activity.isTruckManager && (
                                                    <div
                                                        style={{ backgroundColor: 'gold', borderRadius: 10, height: 20, width: 20 }}
                                                        title={activity?.isTruckManager ? t('truckManager') : undefined}
                                                    />
                                                )}
                                            </div>
                                        </td>
                                        <td>
                                            <StateCell
                                                stateColor={getStateColor(activity.activityState)}
                                                text={capitalizeFirstLetter(t(getStateInfos(activity.activityState)))}
                                            />
                                        </td>

                                        <td>
                                            <div>{formatTime(activity.startTime)}</div>
                                        </td>
                                        <td>
                                            <div>{formatTime(activity.validationTime)}</div>
                                        </td>
                                        <td>
                                            <div className="fx-r fx-justify-c">
                                                {activity.activityTypeId._id !== truckRideActivityTypeId ? (
                                                    <CustomToggle
                                                        checked={activity.isTruckManager}
                                                        disabled
                                                        title={capitalizeFirstLetter(activity.isTruckManager ? t('yes') : t('no'))}
                                                    />
                                                ) : (
                                                    <TruckIcon
                                                        type={
                                                            (trucks.find((value) => value.userId?._id === activity.reportId?.userId?._id)?.truckTypeId
                                                                .designation || '') as 'Semie' | 'Ampliroll' | '8x4' | 'Porte-char' | ''
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </td>
                                        <td>
                                            <div>{activity.observationsWorker}</div>
                                        </td>
                                        <td>
                                            <MachinesCell activity={activity} activityIndex={index} activities={array} disabled hiddenPlusIcon />
                                        </td>
                                        <td>
                                            <ToolsCell activity={activity} activityIndex={index} activities={array} disabled hiddenPlusIcon />
                                        </td>
                                        <td>
                                            <RentalsCell activity={activity} activityIndex={index} activities={array} disabled hiddenPlusIcon />
                                        </td>
                                        <td>
                                            <TempWorkersCell activity={activity} activityIndex={index} activities={array} disabled hiddenPlusIcon />
                                        </td>
                                        <td>
                                            <SubContractorsCell
                                                activity={activity}
                                                activityIndex={index}
                                                activities={array}
                                                disabled
                                                hiddenPlusIcon
                                            />
                                        </td>
                                        <td>
                                            <div>{activity.activityTypeId.designation}</div>
                                        </td>
                                        {user && user?.profileId?.permsLevel <= permissionLevels.SUPERVISION && (
                                            <td>
                                                <Icon
                                                    name="lock"
                                                    onClick={() => {
                                                        handleActivityUnlock(activity._id, activity.reportId._id);
                                                    }}
                                                />
                                            </td>
                                        )}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                {!!reduceDailyTrucksRides().length && (
                    <div className="table-container " style={{ maxHeight: 'auto', marginBottom: 15 }}>
                        <table id="csv-planning-obj">
                            <thead>
                                <tr>
                                    <th scope="col" className="w-30px pos-sy left-0 z-idx-4 pad-0-imp"></th>
                                    <th scope="col" className="w-200px pos-sy left-30px z-idx-4">
                                        {capitalizeFirstLetter(t('site'))}
                                    </th>
                                    <th scope="col" className="w-100px">
                                        {capitalizeFirstLetter(t('company'))}
                                    </th>
                                    <th scope="col" className="w-150px">
                                        {capitalizeFirstLetter(t('plateNumber'))}
                                    </th>
                                    <th scope="col" className="w-200px pos-sy left-230px z-idx-4">
                                        {capitalizeFirstLetter(t('driver'))}
                                    </th>
                                    <th scope="col" className="w-100px">
                                        {capitalizeFirstLetter(t('type'))}
                                    </th>
                                    <th scope="col" className="w-100px pos-sy left-430px z-idx-4">
                                        {capitalizeFirstLetter(t('mode'))}
                                    </th>
                                    <th scope="col" className="w-200px">
                                        <div className="source">{t('source: ')}</div>
                                        <div>{t('loadNature')}</div>
                                    </th>
                                    <th scope="col" className="w-200px">
                                        <div className="source">{t('source: ')}</div>
                                        <div>{t('place')}</div>
                                    </th>
                                    <th scope="col" className="w-150px">
                                        <div className="source">{t('source: ')}</div>
                                        <div>{t('dump')}</div>
                                    </th>
                                    <th scope="col" className="w-150px">
                                        <div className="source">{t('source: ')}</div>
                                        <div>{t('tonnage')}</div>
                                    </th>
                                    <th scope="col" className="w-150px">
                                        <div className="source">{t('source: ')}</div>
                                        <div>{t('hour')}</div>
                                    </th>
                                    <th scope="col" className="w-100px">
                                        {capitalizeFirstLetter(t('siteHour'))}
                                    </th>
                                    <th scope="col" className="w-200px">
                                        <div className="dest">{t('destination: ')}</div>
                                        <div>{t('loadNature')}</div>
                                    </th>
                                    <th scope="col" className="w-200px">
                                        <div className="dest">{t('destination: ')}</div>
                                        <div>{t('place')}</div>
                                    </th>
                                    <th scope="col" className="w-150px">
                                        <div className="dest">{t('destination: ')}</div>
                                        <div>{t('dump')}</div>
                                    </th>
                                    <th scope="col" className="w-150px">
                                        <div className="dest">{t('destination: ')}</div>
                                        <div>{t('tonnage')}</div>
                                    </th>
                                    <th scope="col" className="w-150px">
                                        <div className="dest">{t('destination: ')}</div>
                                        <div>{t('hour')}</div>
                                    </th>
                                    <th scope="col" className="w-150px">
                                        {capitalizeFirstLetter(t('onSiteBackTime'))}
                                    </th>
                                    <th scope="col" className="w-300px">
                                        {capitalizeFirstLetter(t('transfers'))}
                                    </th>
                                    <th scope="col" className="w-200px">
                                        {capitalizeFirstLetter(t('observations'))}
                                    </th>
                                </tr>
                            </thead>
                            <tbody id="fu-planning-obj">
                                {reduceDailyTrucksRides().map((truckRide) => (
                                    <TrucksRidesRow
                                        activities={activities}
                                        dailyTruckRide={truckRide}
                                        dailyTrucksRides={dailyTrucksRides.filter(
                                            (dailyTrucRide) => dailyTrucRide.activityId === truckRide.activityId
                                        )}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SiteTable;
