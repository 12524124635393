import React, { useState } from 'react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { updateActivity } from '../../services/apiSLTP/planning';
import { DataItem } from '../../services/apiSLTP/activities';

interface StartTimeCellProps {
    activity: DataItem;
    activityIndex: number;
    disabled?: boolean;
    refetch: () => void;
}

const StartTimeCell: React.FC<StartTimeCellProps> = ({ activity, activityIndex, disabled = false, refetch }) => {
    const [open, setOpen] = useState(false);
    const [localTime, setLocalTime] = useState<Dayjs | null>(dayjs(activity.startTime));
    const handleTimeChange = async (time: string) => {
        const response = await updateActivity(activity._id, { startTime: time });
        refetch();
    };

    return (
        <TimePicker
            ampm={false}
            onChange={(value) => setLocalTime(value)}
            onAccept={(value) => (value ? handleTimeChange(value?.toISOString()) : null)}
            value={localTime}
            open={open}
            onClose={() => setOpen(false)}
            slotProps={{
                field: {
                    readOnly: true,
                },
                textField: {
                    variant: 'standard',
                    onClick: disabled ? () => {} : () => setOpen(true),
                    sx: {
                        width: 50,
                        textAlign: 'center',
                        '& .MuiInputBase-input': {
                            textAlign: 'center',
                        },
                    },
                },
                inputAdornment: {
                    sx: {
                        display: 'none',
                    },
                },
            }}
            disabled={disabled}
        />
    );
};

export default StartTimeCell;
