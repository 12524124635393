export const reportState = {
    REPORTFILLED: 10,
    REPORTTOUPDATE: 15,
    REPORT: 20,
    PLANNINGSENT: 22,
    PLANNING: 23,
    NEEDSIMPORTED: 24,
    NEEDSSENT: 25,
    NEEDSFILLED: 27,
    NEEDS: 30,
};
export const permissionLevels = {
    ADMIN: 0,
    SUPERVISION: 1,
    PLANNING: 2,
    CONDUC: 3,
    OBSERVERS: 15,
    TERRAIN: 30,
    CHAUFFEUR: 35,
};
export const truckType = {
    SLTP: 'sltp',
    LOCATIER: 'locatier',
};
export const truckMode = {
    APPRO: 0,
    EVAC: 1,
    TRANSFER: 2,
    ONSITE: 3,
    POSE: 4,
    ENLEVEMENT: 5,
    ROTATION: 6,
    DOUBLEFRET: 7,
    BACKANDFORTH: 8,
};
export const dumpsterMode = {
    POSE: 'pose',
    ROTATION: 'rotation',
    ENLEVEMENT: 'enlevement',
};
export const stateProC = {};

export const filters = {
    SITE: 'site',
    JOB: 'job',
};
export const materialState = {
    NEW: 0,
    PRESENT: 5,
    REMOVE: 10,
};
export const crudStatus = {
    OK: 1,
    INACTIVE: 5,
    DELETED: 10,
};
export const localStorageKeys = {
    DATE: 'date',
    NEEDSTAB: 'needsTab',
    PLANNINGTAB: 'planningTab',
    SUPERVISIONTAB: 'supervisionTab',
    ADMINTAB: 'adminTab',
    SUPERVISIONREPORTUSERID: 'supervisionReportUserId',
    SUPERVISIONREPORTSITEID: 'supervisionReportSiteId',
    SUPERVISIONMYSITES: 'supervisionMySites',
    REPORTINGSTARTDATE: 'reportingStartDate',
    REPORTINGENDDATE: 'reportingEndDate',
    REPORTINGREPORTSUSERID: 'reportingReportUserId',
};
export const stepModes = {
    NEEDS: 1,
    PLANNING: 5,
    REPORT: 10,
};

export const truckRideActivityTypeId = '668e3980e29391daaf625cb5';

export const getStateInfos = (state: number) => {
    switch (state) {
        case reportState.REPORTFILLED:
            return 'sent';
        case reportState.REPORT:
            return 'inProgress';
        case reportState.PLANNINGSENT:
            return 'sent';
        case reportState.PLANNING:
            return 'inProgress';
        case reportState.NEEDSIMPORTED:
            return 'imported';
        case reportState.NEEDSSENT:
            return 'sent';
        case reportState.NEEDSFILLED:
            return 'inProgress';
        case reportState.NEEDS:
            return 'inProgress';
        default:
            return '';
    }
};

export const getTruckModeInfos = (mode: number | undefined) => {
    switch (mode) {
        case truckMode.APPRO:
            return 'appro';
        case truckMode.EVAC:
            return 'evac';
        case truckMode.TRANSFER:
            return 'transfer';
        case truckMode.ONSITE:
            return 'onsite';
        case truckMode.ENLEVEMENT:
            return 'enlevement';
        case truckMode.POSE:
            return 'pose';
        case truckMode.ROTATION:
            return 'rotation';
        case truckMode.DOUBLEFRET:
            return 'doubleFret';
        case truckMode.BACKANDFORTH:
            return 'backAndForth';
        default:
            return '';
    }
};

export const getMaterialStateInfos = (state: number) => {
    switch (state) {
        case materialState.NEW:
            return 'new';
        case materialState.PRESENT:
            return 'present';
        case materialState.REMOVE:
            return 'remove';
        default:
            return '';
    }
};

export const getCrudStatusInfos = (status: number) => {
    switch (status) {
        case crudStatus.OK:
            return 'active';
        case crudStatus.INACTIVE:
            return 'inactive';
        case crudStatus.DELETED:
            return 'deleted';
        default:
            return '';
    }
};

export const getPermsLvlInfos = (permsLvl: number) => {
    switch (permsLvl) {
        case permissionLevels.ADMIN:
            return 'admin';
        case permissionLevels.SUPERVISION:
            return 'supervision';
        case permissionLevels.PLANNING:
            return 'planning';
        case permissionLevels.CONDUC:
            return 'conduc';
        case permissionLevels.TERRAIN:
            return 'terrain';
        case permissionLevels.CHAUFFEUR:
            return 'driver';
        default:
            return '';
    }
};
