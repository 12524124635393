import React from "react";

const HomePage: React.FC = () => {
    return (
        <div className="fx-c fx-items-c">
            
        </div>
    )
}

export default HomePage;