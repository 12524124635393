import React, { useEffect } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import Login from '../pages/Login';
import PlanningPage from '../pages/PlanningPage';
import AdminPage from '../pages/AdminPage';
import NotFoundPage from '../pages/NotFoundPage';
import ReportsHistory from '../pages/ReportsHistory';
import NeedsPage from '../pages/NeedsPage';
import HomePage from '../pages/HomePage';
import ProfilePage from '../pages/ProfilePage';
import OnLoadPage from '../pages/OnLoadPage';
import { useAuth } from '../hooks/useAuth';
import NavBar from './NavBar';
import { permissionLevels } from '../utils/enums';
import ReportingPage from '../pages/ReportingPage';
import Footer from '../components/Footer';

const Routers = () => {
    const { authIsLoading, isAuthenticated, user } = useAuth();

    return (
        <Router>
            {!authIsLoading && <NavBar />}
            <main className="fx-c pad-l-5px pad-r-5px">
                <Routes>
                    {authIsLoading ? (
                        <Route path="*" element={<OnLoadPage />} />
                    ) : isAuthenticated ? (
                        <>
                            <Route path="/" element={<HomePage />} />
                            <Route
                                path="/needs"
                                element={user && user?.profileId.permsLevel <= permissionLevels.CONDUC ? <NeedsPage /> : <Navigate to="/" replace />}
                            />
                            <Route path="/profile" element={<ProfilePage />} />
                            <Route
                                path="/planning"
                                element={
                                    user && user?.profileId.permsLevel <= permissionLevels.OBSERVERS ? <PlanningPage /> : <Navigate to="/" replace />
                                }
                            />
                            <Route
                                path="/supervision"
                                element={
                                    user && user?.profileId.permsLevel <= permissionLevels.CONDUC ? <ReportsHistory /> : <Navigate to="/" replace />
                                }
                            />
                            <Route
                                path="/reporting"
                                element={user && user?.profileId.permsLevel <= permissionLevels.PLANNING ? <ReportingPage /> : <Navigate to="/" replace />}
                            />
                            <Route
                                path="/admin"
                                element={
                                    user && user?.profileId.permsLevel <= permissionLevels.PLANNING ? <AdminPage /> : <Navigate to="/" replace />
                                }
                            />

                            <Route path="/login" element={<Navigate to="/" replace />} />
                            <Route path="*" element={<NotFoundPage />} />
                        </>
                    ) : (
                        <>
                            <Route path="/login" element={<Login />} />
                            <Route path="*" element={<Navigate to="/login" replace />} />
                        </>
                    )}
                </Routes>
            </main>
            <Footer />
        </Router>
    );
};

export default Routers;
