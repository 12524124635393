import React from 'react';
import { capitalizeFirstLetter } from '../../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { DailyTruckRide } from '../../../services/apiSLTP/dailyReports/dailyTrucksRides';
import { DataItem } from '../../../services/apiSLTP/activities';
import { getMaterialStateInfos, getTruckModeInfos, truckMode } from '../../../utils/enums';
import { getMaterialStateColor } from '../../../utils/colors';
import { removeDuplicates } from '../../../utils/array';
import SitesRidesRow from './SitesRidesRow';

interface SitesRidesTableProps {
    dailyTrucksRides: DailyTruckRide[];
    activities: DataItem[];
    universalFilter: string;
}

const SitesRidesTable: React.FC<SitesRidesTableProps> = ({ dailyTrucksRides, activities, universalFilter }) => {
    const { t } = useTranslation();

    const activitiesMap = new Map(activities.map((activity) => [activity._id, activity]));

    const sortedDailyTrucksRides = () => {
        return removeDuplicates(
            dailyTrucksRides.filter((value) => {
                return (
                    activitiesMap.get(value.activityId)?.isTruckManager &&
                    (value.truckId.truckTypeId.designation.toLowerCase() === '8x4' || value.truckId.truckTypeId.designation.toLowerCase() === 'semie')
                );
            }),
            ['activityId']
        ).sort((a, b) => {
            const reportA = activities.find((value) => value._id === a?.activityId)?.reportId._id || '';
            const reportB = activities.find((value) => value._id === a?.activityId)?.reportId._id || '';

            if (reportA < reportB) return -1;
            if (reportA > reportB) return 1;

            const activityA = a.activityId;
            const activityB = b.activityId;

            if (activityA < activityB) return -1;
            if (activityA > activityB) return 1;

            return 0;
        });
    };

    const formatTime = (time: string | undefined) => {
        if (time === undefined) return '';
        const date = new Date(time);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    return (
        <div className="table-container">
            <table id="csv-planning-obj">
                <thead>
                    <tr>
                        <th scope="col" className="w-30px pos-sy left-0 z-idx-4 pad-0-imp"></th>
                        <th scope="col" className="w-200px pos-sy left-30px z-idx-4">
                            {capitalizeFirstLetter(t('site'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('codeProC'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('company'))}
                        </th>
                        <th scope="col" className="w-150px">
                            {capitalizeFirstLetter(t('plateNumber'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('driver'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('truckManager'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('type'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('mode'))}
                        </th>
                        <th scope="col" className="w-200px">
                            <div className="source">{t('source: ')}</div>
                            <div>{t('loadNature')}</div>
                        </th>
                        <th scope="col" className="w-200px">
                            <div className="source">{t('source: ')}</div>
                            <div>{t('site')}</div>
                        </th>
                        <th scope="col" className="w-200px">
                            <div className="source">{t('source: ')}</div>
                            <div>{t('quarry')}</div>
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('siteHour'))}
                        </th>
                        <th scope="col" className="w-200px">
                            <div className="dest">{t('destination: ')}</div>
                            <div>{t('loadNature')}</div>
                        </th>
                        <th scope="col" className="w-200px">
                            <div className="dest">{t('destination: ')}</div>
                            <div>{t('site')}</div>
                        </th>
                        <th scope="col" className="w-200px">
                            <div className="dest">{t('destination: ')}</div>
                            <div>{t('quarry')}</div>
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('observations'))}
                        </th>
                    </tr>
                </thead>
                <tbody id="fu-planning-obj">
                    {sortedDailyTrucksRides().map((truckRide) => (
                        <SitesRidesRow
                            activities={activities}
                            dailyTruckRide={truckRide}
                            dailyTrucksRides={dailyTrucksRides.filter(
                                (dailyTrucRide) =>
                                    dailyTrucRide.activityId === truckRide.activityId &&
                                (dailyTrucRide.truckId.truckTypeId.designation.toLowerCase() === '8x4' || dailyTrucRide.truckId.truckTypeId.designation.toLowerCase() === 'semie')
                            )}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SitesRidesTable;
