import { useMutation, MutationFunction, MutationOptions } from 'react-query';
import { ActivityResponse, CreateActivityBody, createActivity, updateActivityTruckManager } from '../../services/apiSLTP/planning';

type UseCreateActivityMutationOptions = MutationOptions<ActivityResponse, Error, CreateActivityBody>;

export const useCreateActivityMutation = (options?: UseCreateActivityMutationOptions) => {
    const activityMutation: MutationFunction<ActivityResponse, CreateActivityBody> = async (data) => {
        try {
            const response: ActivityResponse = await createActivity(data);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during activity');
        }
    };

    return useMutation(activityMutation, options);
};

type UseActivityTruckManagerMutationOptions = MutationOptions<ActivityResponse, Error, { activityId: string; isTruckManager: boolean }>;

export const useActivityTruckManagerMutation = (options?: UseActivityTruckManagerMutationOptions) => {
    const activityMutation: MutationFunction<ActivityResponse, { activityId: string; isTruckManager: boolean }> = async (data) => {
        try {
            const response: ActivityResponse = await updateActivityTruckManager(data.activityId, data.isTruckManager);
            return response;
        } catch (error) {
            throw new Error('An unexpected error occurred during activity');
        }
    };

    return useMutation(activityMutation, options);
};
