export const datesBetween = (daysBefore: number, daysAfter: number) => {
    let dates: Date[] = [];
    let currentDate = new Date();
    const startDate = new Date(currentDate);
    startDate.setDate(startDate.getDate() - daysBefore);
    startDate.setHours(12, 0, 0, 0);

    const endDate = new Date(currentDate);
    endDate.setDate(endDate.getDate() + daysAfter);
    endDate.setHours(12, 0, 0, 0);

    while (startDate.getTime() <= endDate.getTime()) {
        dates.push(new Date(startDate));
        startDate.setDate(startDate.getDate() + 1);
    }
    return dates;
};

export const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow;
};

export const getInOneDayDate = (date: Date) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + 1);
    return newDate;
};

export const getOneDayAgoDate = (date: Date) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() - 1);
    return newDate;
};

export const getSpecificDateFromDate = (date: Date, days: number) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
};

export const isRollbackPossible = (date: string) => {
    const nowDate = new Date();
    const limitDate = new Date(date);
    limitDate.setHours(8, 0, 0, 0);
    return nowDate.getTime() < limitDate.getTime();
};

export const formatDate = (date: Date) => {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
};

export const formatDateToYYYYMMDD = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

export const getTodayPeriod = (date: Date = new Date()): 'normal' | 'halloween' | 'christmas' | 'newYear' => {
    const month = date.getMonth() + 1;
    const day = date.getDate();

    if ((month === 10 && day >= 15) || (month === 11 && day === 1)) {
        return 'halloween';
    }

    if (month === 12) {
        return 'christmas';
    }

    if (month === 1 && day <= 10) {
        return 'newYear';
    }

    return 'normal';
};

export const isSameDayOrBefore = (date1: Date, date2: Date): boolean => {
    const d1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const d2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());

    return d1.getTime() <= d2.getTime();
};

export const isSameDayOrAfter = (date1: Date, date2: Date): boolean => {
    const d1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const d2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());

    return d1.getTime() >= d2.getTime();
};

export const getDaysDifference = (date1: Date, date2: Date): number => {
    const timeDifference = date2.getTime() - date1.getTime();
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
};

export const sameDay = (d1: Date, d2: Date) => {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
};

export const removeDuplicateDates = <T>(data: T[], getDate: (item: T) => string): T[] => {
    const seenDates = new Set<string>();
    return data.filter((item) => {
        const date = getDate(item);
        if (seenDates.has(date)) {
            return false;
        }
        seenDates.add(date);
        return true;
    });
};
