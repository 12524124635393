import React, { useEffect, useState } from 'react';
import ModalBody from './ModalBody';
import CustomToggle from '../CustomToggle';
import CustomTextInput from '../CustomTextInput';
import { useTranslation } from 'react-i18next';
import { crudStatus } from '../../utils/enums';
import { Job } from '../../services/apiSLTP/jobs';
import { useCreateJobMutation, useUpdateJobMutation } from '../../hooks/mutations/useJobMutation';
import { useAuth } from '../../hooks/useAuth';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import ToggleLine from '../ToggleLine';

interface ModalProps {
    job: Job;
    jobs: Job[];
    isEditMode?: boolean;
    setJobs: React.Dispatch<React.SetStateAction<Job[]>>;
    showEditModal: boolean;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

const UpdateJobModal: React.FC<ModalProps> = ({ job, isEditMode = true, setJobs, showEditModal, setShowEditModal, jobs, refetch }) => {
    const { t } = useTranslation();
    const [tmpJob, setTmpJob] = useState(job);

    const { user } = useAuth();

    const {
        mutate: mutateJob,
        isSuccess: isSuccessJob,
        isError: isErrorJob,
        isLoading: isLoadingJob,
        error: errorJob,
        data: dataJob,
    } = useUpdateJobMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating job:', error.message);
        },
    });

    const {
        mutate: addJob,
        isSuccess: isSuccessAdded,
        isError: isErrorAdded,
        isLoading: isLoadingAdded,
        error: errorAdded,
        data: dataAdded,
    } = useCreateJobMutation({
        onSuccess: (data, variables) => {
            refetch();
        },
        onError: (error) => {
            console.error('Error updating job:', error.message);
        },
    });

    const handleDesignationChange = (text: string) => {
        setTmpJob((prevJob) => {
            const newJob = { ...prevJob };
            newJob.designation = text;
            return newJob;
        });
    };

    const handleOrderChange = (text: string) => {
        setTmpJob((prevJob) => {
            const newJob = { ...prevJob };
            newJob.order = parseInt(text);
            return newJob;
        });
    };

    const handleActiveChange = (isActive: boolean) => {
        if (isActive) {
            setTmpJob((prevJob) => {
                const newJob = { ...prevJob };
                newJob.crudStatus = crudStatus.OK;
                return newJob;
            });
        } else {
            setTmpJob((prevJob) => {
                const newJob = { ...prevJob };
                newJob.crudStatus = crudStatus.INACTIVE;
                return newJob;
            });
        }
    };

    const verifyDesignation = () => {
        return true;
    };

    const verifyOrder = () => {
        return true;
    };

    return (
        <ModalBody
            title={t('job')}
            subTitle={isEditMode ? t('update') : t('add')}
            closeText={t('cancel')}
            okText={t('validate')}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            disabledOk={!tmpJob.designation.length || tmpJob.order < 0}
            handleOk={() => {
                isEditMode
                    ? mutateJob({
                          jobId: job._id,
                          data: {
                              designation: tmpJob.designation,
                              order: tmpJob.order,
                              crudStatus: tmpJob.crudStatus,
                          },
                      })
                    : addJob({
                          designation: tmpJob.designation,
                          order: tmpJob.order,
                          crudStatus: tmpJob.crudStatus,
                      });
            }}
        >
            <CustomTextInput
                value={tmpJob.designation}
                label={t('name')}
                onChange={handleDesignationChange}
                //error={!verifyDesignation()}
                errorText={t('error')}
                required
            />
            <ToggleLine checked={tmpJob.crudStatus === crudStatus.OK} onChange={handleActiveChange} title={t('active')} />
            <CustomTextInput value={tmpJob.order} type="number" label={t('order')} onChange={handleOrderChange} errorText={t('error')} required />
        </ModalBody>
    );
};

export default UpdateJobModal;
